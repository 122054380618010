import React, { useRef, useState } from 'react';
import ReactECharts from 'echarts-for-react';

import useTheme from 'hooks/common/useTheme';
import filterEmptyParams from 'utils/params/filterEmptyParams';

const Chart = ({ width, height, style, onChartReady, ...props }) => {
  const { theme } = useTheme();
  const chartRef = useRef();

  const [currentWidth, setCurrentWidth] = useState(null);

  const handleChartReady = instance => {
    setCurrentWidth(width);

    if (onChartReady) {
      onChartReady(instance);
    }
  };

  return (
    <ReactECharts
      ref={chartRef}
      notMerge={true}
      lazyUpdate={true}
      theme={theme}
      style={{ ...filterEmptyParams({ width: currentWidth, height }), ...style }}
      onChartReady={handleChartReady}
      {...props}
    />
  );
};

export default Chart;
