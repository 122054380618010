import request from './request.service';
import asyncErrorHandler from 'utils/requests/errorHandler';

import { apiUrls } from 'constants/urls';
import filterEmptyParams from 'utils/params/filterEmptyParams';

export const getAccountAvgHashrate = async accountId => {
  const callback = async () => {
    return await request(apiUrls.statistics.hashrateStatsByAcc(accountId));
  };

  return await asyncErrorHandler(callback);
};

export const getAccountProfitChart = async accountId => {
  const callback = async () => {
    return await request(apiUrls.charts.profitChart(accountId));
  };

  return await asyncErrorHandler(callback);
};

export const getProfitAndHashrate = async ({ accountId, coin, page, pageSize, startDate, endDate }) => {
  const callback = async () => {
    return await request(apiUrls.statistics.profitTable(accountId), {
      params: filterEmptyParams({ coin, page, per_page: pageSize, from: startDate, to: endDate }),
    });
  };

  return await asyncErrorHandler(callback);
};

export const getAccountsSummary = async () => {
  const callback = async () => {
    return await request(apiUrls.accounts.accountSummary);
  };

  return await asyncErrorHandler(callback);
};
