const detectElementBottom = (event, callback = () => {}) => {
  const target = event.target;

  const deviationNegative = -1;
  const deviationPositive = 1;

  const isBottom =
    target.scrollHeight - target.scrollTop > target.clientHeight + deviationNegative &&
    target.scrollHeight - target.scrollTop < target.clientHeight + deviationPositive;

  if (isBottom) {
    callback();
  }
};

export default detectElementBottom;
