import { PARAGRAPH_PATH, PREFIX_PATH, TITLE_PATH } from '../constants/agreement';

const getNumbersArray = length => {
  return Array.from({ length }, (_, i) => i + 1);
};

const getFullAgreementStructure = (structure = []) => {
  return structure.map(item => ({
    title: `${PREFIX_PATH}.${item.title}.${TITLE_PATH}`,

    paragraphs: getNumbersArray(item.paragraphsCount).map(
      count => `${PREFIX_PATH}.${item.title}.${PARAGRAPH_PATH}${count}`,
    ),
  }));
};

export default getFullAgreementStructure;
