import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

import Paragraph from './components/Paragraph';
import { BaseModal, Button } from 'components';

import detectElementBottom from 'utils/scroll/detectElementBottom';
import getFullAgreementStructure from './utils/getFullAgreementStructure';

import { AgreementStructure } from './constants/agreement';

import './AgreementModal.styles.scss';

const { Text } = Typography;

const AgreementModal = ({ open, onClose = () => {}, onCancel = () => {}, onAgree = () => {}, hideButtons }) => {
  const docRef = useRef(null);
  const { t } = useTranslation();

  const [isDocumentBottom, setIsDocumentBottom] = useState(false);

  const structure = getFullAgreementStructure(AgreementStructure);

  const onScroll = event => {
    detectElementBottom(event, () => setIsDocumentBottom(true));
  };

  const handleAgree = () => {
    onAgree();
    onClose();
  };

  const handleDisagree = () => {
    onCancel();
    onClose();
  };

  const checkScrollableDocument = open => {
    if (!open || !docRef.current) {
      return;
    }

    if (docRef.current.scrollHeight === docRef.current.clientHeight) {
      setIsDocumentBottom(true);
    } else {
      setIsDocumentBottom(false);
    }
  };

  return (
    <BaseModal
      width={850}
      open={open}
      onCancel={onClose}
      afterOpenChange={checkScrollableDocument}
      className="np-sign-up-agreement-modal"
      footer={
        !hideButtons && (
          <div className="np-sign-up-agreement-modal__footer">
            <Button size="large" type="default" onClick={handleDisagree}>
              {t('form.button.disagree')}
            </Button>

            <Button size="large" disabled={!isDocumentBottom} onClick={handleAgree}>
              {t('form.button.agreeAndContinue')}
            </Button>
          </div>
        )
      }
    >
      <div className="np-sign-up-agreement-modal__header">
        <Text className="np-sign-up-agreement-modal__title">{t('signUp.agreementModal.header.title')}</Text>

        {!hideButtons && <Text>{t('signUp.agreementModal.header.text1')}</Text>}
        <Text>{t('signUp.agreementModal.header.text2')}</Text>
      </div>

      <div className="np-sign-up-agreement-modal__text-wrapper" ref={docRef} onScroll={onScroll}>
        {structure.map(item => (
          <div key={item.title} className="np-sign-up-agreement-modal__text-block">
            <Text className="np-sign-up-agreement-modal__title">{t(item.title)}</Text>

            <div className="np-sign-up-agreement-modal__text-content">
              {item.paragraphs.map(paragraph => (
                <Paragraph key={paragraph} value={paragraph} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </BaseModal>
  );
};

export default AgreementModal;
