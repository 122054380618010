import { createSlice } from '@reduxjs/toolkit';

export const landingSlice = createSlice({
  name: 'landing',
  initialState: {
    showSignUp: false,
  },
  reducers: {
    setShowSignUp: (state, action) => {
      state.showSignUp = action.payload;
    },
  },
});

export const { setShowSignUp } = landingSlice.actions;

export default landingSlice.reducer;
