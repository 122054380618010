import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import StepLayout from '../../common/StepLayout';
import Requirements from './components/Requirements';
import IdentifyVerificationForm from './components/IdentifyVerificationForm';

import useCaptcha from 'hooks/forms/useCaptcha';
import useNotifications from 'hooks/common/useNotifications';
import useCaptchaHandler from 'hooks/forms/useCaptchaHandler';

const IdentifyVerification = ({ includeCaptcha, onFinish = () => {}, requestCallback = async () => {}, ...props }) => {
  const { t } = useTranslation();
  const { notificationApi } = useNotifications();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { onCaptchaError } = useCaptchaHandler({ setError });
  const { isCaptchaError, isCaptchaReady, checkCaptchaError, getCaptchaParams } = useCaptcha('securityVerification');

  const showCaptcha = includeCaptcha && isCaptchaError;

  const handleError = ({ error, errorMessage }) => {
    const captchaError = checkCaptchaError(error);

    if (captchaError) {
      onCaptchaError(captchaError);
      return;
    }

    setError(null);
    notificationApi.error({ message: t(errorMessage) });
  };

  const handleFinish = async ({ captchaV2, ...values }) => {
    setLoading(true);

    const captchaParams = getCaptchaParams(captchaV2);

    const { success, errorMessage, error } = await requestCallback({ ...values, ...captchaParams });

    if (success) {
      onFinish(values);
    } else {
      handleError({ error, errorMessage });
    }

    setLoading(false);
  };

  return (
    <StepLayout
      title={t('resetProcess.steps.identityVerification.content.title')}
      description={t('resetProcess.steps.identityVerification.content.desc')}
    >
      <Requirements />
      <IdentifyVerificationForm
        error={error}
        loading={loading}
        onFinish={handleFinish}
        includeCaptcha={showCaptcha}
        disabled={!isCaptchaReady}
        {...props}
      />
    </StepLayout>
  );
};

export default IdentifyVerification;
