export const StorageKey = {
  SELECTED_ACCOUNT_ID: 'selectedAccountId',
  THEME: 'theme',
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  SIGN_UP_TOKEN: 'signUpToken',
  USER_UUID: 'userUuid',

  DISABLE_RELOAD_USER: '_disableReloadUser',
};
