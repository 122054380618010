import React, { useState } from 'react';
import { Dropdown, Space, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { MenuVariant } from './constants/variant';

import './Menu.styles.scss';
import { ArrowDownIcon } from 'assets/images';

const { Text } = Typography;

const Menu = ({
  title,
  items = [],
  style,
  width,
  menu,
  variant = MenuVariant.DEFAULT,
  onMenuItemClick = () => {},
  menuClassName = '',
  className = '',
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const variantClass = `np-menu--${variant}`;
  const variantDropdownClass = `np-menu__dropdown--${variant}`;

  const handleMenuItemClick = item => {
    if (onMenuItemClick) {
      onMenuItemClick(item);
    }

    setOpen(false);
  };

  const menuProps = {
    items,
    className: menuClassName,
    triggerSubMenuAction: 'click',
    onClick: handleMenuItemClick,
    ...menu,
  };

  const onOpenChange = open => {
    setOpen(open);
  };

  return (
    <Dropdown
      open={open}
      onOpenChange={onOpenChange}
      menu={menuProps}
      trigger={['click']}
      className={`np-menu ${variantClass} ${className}`}
      dropdownRender={menu => (
        <div className={`np-menu__dropdown ${variantDropdownClass}`} style={{ ...style, width }}>
          {menu}
        </div>
      )}
      {...props}
    >
      <Space size={variant === MenuVariant.OUTLINED ? 10 : 8} className="np-menu__title" style={{ width }}>
        <Text>{title}</Text>
        {variant === MenuVariant.OUTLINED ? <ArrowDownIcon /> : <DownOutlined />}
      </Space>
    </Dropdown>
  );
};

export default Menu;
