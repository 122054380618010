import { useEffect } from 'react';

import getUserUuid from 'utils/auth/getUserUuid';

const useUserUuid = () => {
  useEffect(() => {
    getUserUuid();
  }, []);
};

export default useUserUuid;
