import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import { Language } from 'constants/languages';
import watcherHasPermission from 'utils/auth/watcherHasPermission';
import { WatcherPermissions } from 'constants/watcher';
import useUserData from 'hooks/user/useUserData';

const JivoChatLangCodes = {
  [Language.RU]: 'aDmAqYq8HR',
  [Language.EN]: 'MjHldvBol4',
};

const JivochatMarkup = () => {
  const { language } = useSelector(state => state.language);
  const { watcher, pending } = useSelector(state => state.watcherAuth);
  const { user, loading } = useUserData();

  const notAuthorized = (!user && !watcher) || pending || loading;
  const isAdmin = watcherHasPermission({ watcher, requiredPermissions: WatcherPermissions.ADMIN });

  const showChat = !!user || (!!watcher && !isAdmin) || notAuthorized;

  const src = `//code.jivo.ru/widget/${JivoChatLangCodes[language]}`;

  useEffect(() => {
    if (!showChat) {
      const jivoChatElement = document.querySelector('jdiv');

      jivoChatElement?.remove();
    }
  }, [showChat]);

  return (
    showChat && (
      <Helmet async>
        <script src={src} async></script>
      </Helmet>
    )
  );
};

export default JivochatMarkup;
