import React from 'react';
import { Button as AntdButton } from 'antd';

import { ButtonColor } from './constants/types';

import './Button.styles.scss';

const Button = ({
  type = 'primary',
  shape = 'round',
  color = ButtonColor.PRIMARY,
  children,
  maxWidth,
  className,
  style,
  ...props
}) => {
  const colorClass = `np-button__${type}--${color}`;

  return (
    <AntdButton
      type={type}
      shape={shape}
      style={{ maxWidth, ...style }}
      className={`np-button ${colorClass} ${className}`}
      {...props}
    >
      {children}
    </AntdButton>
  );
};

export default Button;
