import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import StepLayout from '../../common/StepLayout';
import SecurityVerificationForm from './components/SecurityVerificationForm';

import useCaptcha from 'hooks/forms/useCaptcha';
import useUserData from 'hooks/user/useUserData';
import useNotifications from 'hooks/common/useNotifications';
import useCaptchaHandler from 'hooks/forms/useCaptchaHandler';

import { ErrorType } from 'constants/errors';

import './SecurityVerification.styles.scss';

const SecurityVerification = ({
  onFinish = () => {},
  resendCode = async () => {},
  requestCallback = async () => {},
  includeCaptcha,
  ...props
}) => {
  const { t } = useTranslation();
  const { user } = useUserData();
  const { notificationApi } = useNotifications();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isCodeValid, setIsCodeValid] = useState(true);

  const { onCaptchaError } = useCaptchaHandler({ setError });
  const { isCaptchaError, isCaptchaReady, checkCaptchaError, getCaptchaParams } = useCaptcha('securityVerification');

  const showCaptcha = includeCaptcha && isCaptchaError;

  const handleError = ({ error, errorMessage }) => {
    const captchaError = checkCaptchaError(error);

    if (captchaError) {
      onCaptchaError(captchaError);
      return;
    }

    setError(null);
    setIsCodeValid(error?.body?.key !== ErrorType.INVALID_CODE);
    notificationApi.error({ message: t(errorMessage) });
  };

  const handleFinish = async ({ captchaV2, ...values }) => {
    setLoading(true);

    const captchaParams = getCaptchaParams(captchaV2);

    const { success, errorMessage, error } = await requestCallback({ ...values, ...captchaParams });

    if (success) {
      onFinish();
    } else {
      handleError({ error, errorMessage });
    }

    setLoading(false);
  };

  return (
    <StepLayout
      title={
        <>
          {t('resetProcess.steps.securityVerification.content.title')}{' '}
          <span className="np-reset-process-security-verify__accent">{user?.email}</span>
        </>
      }
      description={t('resetProcess.steps.securityVerification.content.desc')}
    >
      <SecurityVerificationForm
        loading={loading}
        disabled={!isCaptchaReady}
        onFinish={handleFinish}
        resendCode={resendCode}
        valid={isCodeValid}
        error={error}
        includeCaptcha={showCaptcha}
        {...props}
      />
    </StepLayout>
  );
};

export default SecurityVerification;
