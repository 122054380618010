import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider, App as AntdApp } from 'antd';
import { HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import Routes from 'routes/Routes';
import { IconsMarkup, OpenGraphMarkup } from 'components';
import JivochatMarkup from 'components/layouts/common/JivochatMarkup';

import useTheme from 'hooks/common/useTheme';
import useUtmTags from 'hooks/user/useUtmTags';
import useUserUuid from 'hooks/user/useUserUuid';
import useLocalStorageField from 'hooks/common/useLocalStorageField';

import accessTokenChangedCallback from 'utils/auth/accessTokenChangedCallback';

import { clientUrl } from 'configs';
import { AntLanguage } from 'constants/languages';
import { StorageKey } from 'constants/localStorage';

import 'assets/styles/common.scss';

const queryClient = new QueryClient();

function App() {
  const { language } = useSelector(state => state.language);
  const { themeConfig, initTheme } = useTheme();

  useEffect(() => {
    initTheme();
  }, [initTheme]);

  useUtmTags();
  useUserUuid();
  useLocalStorageField(StorageKey.ACCESS_TOKEN, accessTokenChangedCallback);

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider theme={themeConfig} locale={AntLanguage[language]}>
          <AntdApp>
            <IconsMarkup />

            <OpenGraphMarkup
              title="Neopool"
              description="Join our mining pool and experience the ease, convenience, and profitability of BTC mining firsthand."
              image={`${clientUrl}/img/other/neopool-preview.png`}
              imageWidth={420}
              imageHeight={200}
            />

            <JivochatMarkup />

            <Routes />
          </AntdApp>
        </ConfigProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;
