import React from 'react';

import Header from './components/Header';
import Footer from './components/Footer';

import './PublicLayout.styles.scss';

const PublicLayout = ({ children, showBanner, showTheme, gap }) => {
  return (
    <div style={{ gap }} className="np-public-layout">
      <Header showBanner={showBanner} showTheme={showTheme} />

      <div className="np-public-layout__content">{children}</div>

      <Footer />
    </div>
  );
};

export default PublicLayout;
