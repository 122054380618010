import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import Logo from '../Logo';
import { AgreementModal } from 'modules';

import useModal from 'hooks/common/useModal';

import './Footer.styles.scss';
import MainContainer from 'components/layouts/common/MainContainer';

const { Text } = Typography;

const Footer = () => {
  const { t } = useTranslation();
  const { open, onOpen, onClose } = useModal();

  return (
    <MainContainer className="np-public-layout-footer">
      <div className="np-public-layout-footer__left">
        <Logo />
      </div>

      <div className="np-public-layout-footer__right">
        <div className="np-public-layout-footer__right-text">
          <Text>{t('landing.footer.name')}</Text>
          <Text>{t('landing.footer.location')}</Text>
        </div>

        <Text role="button" onClick={onOpen}>
          {t('landing.footer.termsOfUse')}
        </Text>
      </div>

      <AgreementModal hideButtons open={open} onClose={onClose} />
    </MainContainer>
  );
};

export default Footer;
