export const baseUrl = window._DOMAIN || process.env.REACT_APP_DOMAIN;

export const clientUrl = window._CLIENT_DOMAIN || process.env.REACT_APP_CLIENT_DOMAIN;

export const adminUrl = window._ADMIN_DOMAIN || process.env.REACT_APP_ADMIN_DOMAIN;

export const captchaKey = window._GOOGLE_CAPTCHA_KEY || process.env.REACT_APP_GOOGLE_CAPTCHA_KEY;

export const captchaKeyV2 = window._GOOGLE_CAPTCHA_KEY2 || process.env.REACT_APP_GOOGLE_CAPTCHA_KEY2;

export const chatBotUrl = window._CHAT_BOT_DOMAIN || process.env.REACT_APP_CHAT_BOT_DOMAIN;

export const notificationBotChannel =
  window._NOTIFICATION_BOT_CHANNEL || process.env.REACT_APP_NOTIFICATION_BOT_CHANNEL;

export const testWalletPrefix = window._TEST_WALLET_PREFIX || process.env.REACT_APP_TEST_WALLET_PREFIX;

export const allowGeneralEmail = window._ALLOW_GENERAL_EMAIL || process.env.REACT_APP_ALLOW_GENERAL_EMAIL;

export const minActiveReferrals = +window._MIN_ACTIVE_REFERRALS || +process.env.REACT_APP_MIN_ACTIVE_REFERRALS || 0;

const disableWithdrawalVar = window._DISABLE_WITHDRAWAL || process.env.REACT_APP_DISABLE_WITHDRAWAL;
export const disableWithdrawal = disableWithdrawalVar === 'true' ? true : false;
