import { CoinBTC, CoinFB } from 'assets/images';

export const Coin = {
  BTC: 'BTC',
  FB: 'FB',
};

export const CoinLabel = {
  [Coin.BTC]: 'BTC',
  [Coin.FB]: 'FB',
};

export const CoinFullLabel = {
  [Coin.BTC]: 'Bitcoin',
  [Coin.FB]: 'Fractal Bitcoin',
};

export const CoinsLabels = Object.values(Coin).map(coin => ({ value: coin, label: CoinLabel[coin] }));

export const CoinIcon = {
  [Coin.BTC]: CoinBTC,
  [Coin.FB]: CoinFB,
};
