import { useQuery } from '@tanstack/react-query';
import { getWorkerLogs } from 'services/admin/adminLogs.service';

const STALE_TIME_MS = 1000;

const useWorkerLogsQuery = ({ workername, username }, options = {}) => {
  return useQuery({
    queryKey: ['worker-logs', workername, username],
    queryFn: () => getWorkerLogs({ workername, username }),
    staleTime: STALE_TIME_MS,
    gcTime: STALE_TIME_MS,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    ...options,
  });
};

export default useWorkerLogsQuery;
