import { configureStore } from '@reduxjs/toolkit';

import userReducer from 'store/reducers/user';
import themeReducer from 'store/reducers/theme';
import languageReducer from 'store/reducers/lang';
import landingReducer from 'store/reducers/landing';
import paymentReducer from 'store/reducers/payment';
import accountsReducer from 'store/reducers/accounts';
import internalNotificationsReducer from 'store/reducers/internalNotifications';

import watcherAuthReducer from 'store/reducers/watcher/watcherAuth';
import watcherAccountsReducer from 'store/reducers/watcher/accounts';

export default configureStore({
  reducer: {
    user: userReducer,
    theme: themeReducer,
    landing: landingReducer,
    payment: paymentReducer,
    accounts: accountsReducer,
    language: languageReducer,
    internalNotifications: internalNotificationsReducer,

    watcherAuth: watcherAuthReducer,
    watcherAccounts: watcherAccountsReducer,
  },
});
