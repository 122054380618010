import { WatcherPermissions } from 'constants/watcher';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import watcherHasPermission from 'utils/auth/watcherHasPermission';

const useWatcherData = () => {
  const { watcher, accessKey } = useSelector(state => state.watcherAuth);
  const isAdmin = watcherHasPermission({ watcher, requiredPermissions: WatcherPermissions.ADMIN });

  const [searchParams] = useSearchParams();

  const urlAccessKey = searchParams.get('access_key');

  return {
    watcher,
    accessKey,
    isAdmin,
    isWatcherPage: !!watcher && !!urlAccessKey,
  };
};

export default useWatcherData;
