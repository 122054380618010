import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from 'components';

import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

import { publicUrls } from 'constants/urls';
import { ButtonColor } from 'components/buttons/Button';

import './AuthButtons.styles.scss';

const AuthButtons = () => {
  const { t } = useTranslation();
  const { isMobileView } = useWindowResponsiveness(740);
  const { showSignUp } = useSelector(state => state.landing);

  const location = useLocation();

  const isLanding = location.pathname === publicUrls.main;
  const showAnimation = showSignUp || !isLanding;

  return (
    <div className="np-public-layout-auth-button">
      <NavLink to={publicUrls.login}>
        <Button type="default" size="large" className="np-public-layout-auth-button-login">
          {t('landing.header.button.signIn')}
        </Button>
      </NavLink>

      {!isMobileView && (
        <NavLink
          to={publicUrls.singUp}
          className={`np-public-layout-auth-button-registration ${showAnimation ? 'show-animation' : 'hide-animation'}`}
        >
          <Button color={ButtonColor.PRIMARY_LIGHT} size="large">
            {t('landing.header.button.signUp')}
          </Button>
        </NavLink>
      )}
    </div>
  );
};

export default AuthButtons;
