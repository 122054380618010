export const FontFamily = {
  TEXT: 'Satoshi',
  TEXT_2: 'Nunito',
  NUMBERS: 'Roboto',
  DEFAULT: 'sans-serif',
  MONO: 'Fira Mono',
  LANDING: 'SF Pro',
};

export const DefaultFontFamily = `${FontFamily.NUMBERS}, ${FontFamily.TEXT}, ${FontFamily.TEXT_2}, ${FontFamily.DEFAULT}`;

export const LandingFontFamily = `${FontFamily.LANDING}, ${FontFamily.DEFAULT}`;
