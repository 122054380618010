import React from 'react';
import { Typography } from 'antd';
import { Trans } from 'react-i18next';

const { Text } = Typography;

const Paragraph = ({ value = '' }) => {
  return (
    <Text>
      <Trans i18nKey={value} />
    </Text>
  );
};

export default Paragraph;
