import React from 'react';

import Files from '../Files';
import { FormButtons } from 'modules';
import { Form, Captcha } from 'components';
import CaptchaErrorAlert from 'modules/alerts/CaptchaErrorAlert';

import useForm from 'hooks/forms/useForm';
import useValidation from 'hooks/forms/useValidation';

import { CaptchaErrorType } from 'constants/errors';

import './IdentifyVerificationForm.styles.scss';

const IdentifyVerificationForm = ({ error, loading, disabled, includeCaptcha, onFinish = () => {} }) => {
  const [form] = Form.useForm();
  const rules = useValidation();

  const { submitDisabled } = useForm(form);

  const handleFinish = values => {
    onFinish(values);
  };

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      layout="vertical"
      requiredMark="optional"
      validateTrigger="onSubmit"
      name="reset-pay-pass-identify-verify"
      className="np-reset-process-identify-verify__form"
    >
      <Form.Item name="files" rules={[rules.required]}>
        <Files disabled={loading} />
      </Form.Item>

      {includeCaptcha && (
        <Form.Item
          name="captchaV2"
          validateFirst
          rules={[rules.requiredWithMessage('validation.сaptcha.required')]}
          className="np-reset-process-identify-verify__form-captcha"
        >
          <Captcha />
        </Form.Item>
      )}

      {error === CaptchaErrorType.COMPLETELY_FAILED && <CaptchaErrorAlert />}

      <FormButtons marginTop={0} submitDisabled={submitDisabled || disabled} loading={loading} />
    </Form>
  );
};

export default IdentifyVerificationForm;
