import { useTranslation } from 'react-i18next';

import useNotifications from 'hooks/common/useNotifications';

import { CaptchaErrorType } from 'constants/errors';

const useCaptchaHandler = ({ setError = () => {} }) => {
  const { t } = useTranslation();
  const { notificationApi } = useNotifications();

  const onCaptchaError = captchaError => {
    switch (captchaError) {
      case CaptchaErrorType.COMPLETELY_FAILED: {
        setError(captchaError);

        notificationApi.error({ message: t(`requestErrors.${captchaError}`) });
        return;
      }

      case CaptchaErrorType.FIRST_STEP_FAILED:
      default: {
        setError(null);
        return;
      }
    }
  };

  return { onCaptchaError };
};

export default useCaptchaHandler;
