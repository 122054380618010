import request from '../request.service';
import { apiAdminUrls } from 'constants/urls';
import createFormData from 'utils/params/createFormData';
import asyncErrorHandler from 'utils/requests/errorHandler';
import filterEmptyParams from 'utils/params/filterEmptyParams';
import { adminUrl } from 'configs';

export const startProcessWorkersLog = async ({ workername, date }) => {
  const callback = async () => {
    return await request(apiAdminUrls.logs.all, {
      method: 'POST',
      baseUrl: adminUrl,
      isJson: false,
      body: createFormData(filterEmptyParams({ workername, time_to: date })),
    });
  };

  return await asyncErrorHandler(callback);
};

export const getWorkerLogs = async ({ workername, username }) => {
  const callback = async () => {
    return await request(apiAdminUrls.logs.all, {
      baseUrl: adminUrl,
      params: filterEmptyParams({ workername, username }),
    });
  };

  return await asyncErrorHandler(callback);
};

export const getWorkerLogById = async id => {
  const callback = async () => {
    return await request(apiAdminUrls.logs.byId(id), {
      baseUrl: adminUrl,
    });
  };

  return await asyncErrorHandler(callback);
};
