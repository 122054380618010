import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Menu } from 'components';

import { setLanguage } from 'store/reducers/lang';
import toTitleCase from 'utils/common/toTitleCase';

import { LanguageMenuItems } from 'constants/languages';

const LanguageSelect = ({ titleCase, ...props }) => {
  const dispatch = useDispatch();
  const { language } = useSelector(state => state.language);

  const transformLabel = label => {
    if (titleCase) {
      return toTitleCase(label);
    }

    return label;
  };

  const transformedItems = LanguageMenuItems.map(item => ({ ...item, label: transformLabel(item.label) }));

  const languageLabel = transformedItems.find(e => e.key === language)?.label;

  const onLangChange = e => {
    dispatch(setLanguage(e.key));
  };

  const menuConfig = {
    selectable: true,
    selectedKeys: [language],
  };

  return (
    <Menu title={languageLabel} items={transformedItems} onMenuItemClick={onLangChange} menu={menuConfig} {...props} />
  );
};

export default LanguageSelect;
