export const ruLng = {
  translation: {
    menu: {
      minerRanking: 'Рейтинг доходности устройств',
      announcement: 'Новости пула',
      powCommunity: 'Монеты PoW',

      dashboard: 'Мониторинг',
      workers: 'Устройства',
      assets: 'Активы',

      language: 'Язык',
      theme: {
        label: 'Тема',
        submenu: {
          light: 'Светлая',
          dark: 'Тёмная',
        },
      },

      userStatus: {
        vip: 'VIP',
        ambassador: 'Амбассадор',
      },

      userProfile: {
        dashboard: 'Главная',
        workers: 'Устройства',
        assetsAndPayments: 'Активы и платежи',
        bills: 'Операции по счёту',
        paymentSettings: 'Настройки выплат',
        accountSettings: 'Настройки аккаунтов',
        profile: 'Профиль',
        subAccounts: 'Суб-аккаунты',
        watchers: 'Ссылки наблюдения',
        notifications: 'Оповещения',
        referral: 'Реферальные вознаграждения',
        mining: 'Mайнинг',
        depositsAndWithdrawal: 'Переводы и выплаты',
        apiSettings: 'Настройки API',
        apiManagement: 'Управление API',

        subAccountsInfo: {
          hashrate: 'Мощность (24 ч)',
          workerStatus: 'Устройства',
          active: 'Активные',
          inactive: 'Неактивные',
          offline: 'Оффлайн',

          autoTransfers: {
            enabled: 'Автопереводы включены',
            disabled: 'Автопереводы выключены',

            tooltip: {
              autoWithdrawal: 'Автовыплаты',
              autoInternalTransfer: 'Автоматические внутренние переводы',
              autoInternalTransferAccs: 'С {{count}} аккаунтов',
            },
          },
        },

        logOut: 'Выход',
        logInBtn: 'Вход',
        signUpBtn: 'Регистрация',
      },
    },

    banners: {
      blockchainLifeInDubai: {
        title: 'Мы на Blockchain<br/>Life в Дубае',
        location: 'Booth A10',
        date: '22-23 октября',
      },
    },

    noMiners: {
      title: 'Здесь будет информация о майнинге',
      desc: 'После подключения первого устройства Вам станет доступна инфомация о Вашем майнинге',
      button: 'Подключите первое устройство',
    },

    landing: {
      banner: {
        link: 'Зарегистрируйтесь',
        text: 'сейчас и получите 0% комиссию на первый месяц майнинга',
      },

      header: {
        button: {
          signIn: 'Войти',
          signUp: 'Зарегистрироваться',
        },
      },

      footer: {
        name: 'Neopool LTD',
        location: 'Apollo Business Solutions (Pty) Ltd, 103 Sham Peng Tong Plaza, Victoria, Mahé, Seychelles',
        termsOfUse: 'Пользовательское соглашение',
      },

      welcomeSection: {
        title: 'Раскройте весь потенциал вашего хэшрейта',
        desc: 'Попробуйте наши инженерные решения для максимизации прибыли от майнинга',
        btn: 'Зарегистрироваться',
      },

      aboutUsSection: {
        block1: {
          title: 'Собственные алгоритмы оптимизации майнинга',
          desc: 'Наши технологические инновации обеспечивают лучшую на рынке доходность',
        },

        block2: {
          title: 'Прозрачное распределение начислений',
          desc: 'Честный расчет вознаграждений без скрытых комиссий',
        },

        block3: {
          title: 'Гибкие настройки и кастомная разработка',
          desc: 'Разработаем индивидуальные решения под ваши конкретные задачи',
        },
      },

      seoSection: {
        quote:
          'Neopool — результат многолетнего опыта и глубокого понимания индустрии, где профессионализм, инновации и прозрачность <AccentText>помогают каждому майнеру</AccentText> извлечь максимум из инвестиций',
        seoName: 'Андрей Копейкин',
        seoDesc: 'CEO, Neopool',
      },

      technologiesSection: {
        title: 'Передовые технологии для удобного майнинга',

        block1: {
          title: 'Быстрая связь с ближайшими нодами',
          desc: 'Оптимизируем маршрут данных, подключаясь к лучшим биткоин-нодам',
        },

        block2: {
          title: 'Уникальные шаблоны подписания блоков',
          desc: 'Используем собственные темплейты для более эффективной сборки блоков',
        },

        block3: {
          title: 'Адаптивная сложность под ваше оборудование',
          desc: 'Подстраиваем уровень сложности шар под конкретный ASIC, обеспечивая максимальную производительность майнинга',
        },

        block4: {
          title: 'Ежедневный автовывод средств',
          desc: 'Автоматический доступ к вашим заработкам без задержек и комиссий',
        },

        block5: {
          title: 'API и экспорт данных в любом формате',
          desc: 'Легкая интеграция с вашими системами, удобное построение и анализ отчетов',
        },

        block6: {
          title: 'Персональная поддержка от экспертов',
          desc: 'Мы всегда на связи, чтобы помочь вам достичь максимальной эффективности в майнинге',
        },
      },

      contactsSection: {
        title: 'Индивидуальный подход для профессиональных майнеров',
        desc: 'Если ваша мощность майнинга превышает 5 PH/s, напишите нам, и мы предложим вам минимальную комиссию на рынке!',
        btn: 'Получить лучшие условия',
      },

      statisticsSection: {
        title: 'Максимальная прозрачность и контроль майнинга',

        block: {
          rewardModel: 'Модель выплат',
          poolHashrate: 'Хешрейт пула',
          minimumPayout: 'Минимальная выплата',
          profitability: 'Доходность на Neopool',
          profitabilityBtc: 'Доходность за сутки',
          coin: 'Монета',
          coinPrice: 'Цена монеты',
          networkHashrate: 'Хешрейт сети',
          difficulty: 'Сложность',
          timeUntilDifficultyAdjustment: 'Время до изменения сложности',
          expectedDifficultyChange: 'Ожидаемое изменение сложности',
        },

        chart: {
          totalHashrate: 'Мощность сети биткоин',
          revenueUsdt: 'Доходность на Neopool',
        },

        tooltip: {
          rewardModel:
            'Neopool использует модель Full Pay-Per-Share (FPPS), чтобы вы получали вознаграждение не только за каждую принятую шару, но справедливую долю от доходов с транзакционных комиссий блока. Таким образом, вы заранее знаете, сколько заработаете, независимо от того, когда будет найден реальный блок.',
        },
      },

      joinUsSection: {
        title: 'Максимизируйте ваш доход от майнинга',
        btn: 'Присоединиться к Neopool',
      },

      questionsSection: {
        faq1: {
          title: 'Чем Neopool отличается от других майнинг-пулов?',
          desc: 'Neopool — это майнинг-пул, созданный профессионалами для профессионалов. Мы отличаемся передовыми технологиями, обеспечивающими максимальную доходность для наших пользователей. Наши уникальные алгоритмы оптимизации майнинга превосходят другие пулы по эффективности. Мы предлагаем индивидуальные условия по комиссии, гибкую разработку под нужды клиентов и полную прозрачность в распределении доходов. Кроме того, мы обеспечиваем персональную поддержку 24/7, чтобы каждый майнер мог получить максимальную выгоду от сотрудничества с нами.',
        },

        faq2: {
          title: 'Какие технологии и уникальные особенности предлагает Neopool?',
          desc: 'Neopool использует передовые технологии и уникальные решения для повышения эффективности майнинга:',
          list: {
            item1:
              'инновационные алгоритмы оптимизации: наши алгоритмы повышают вашу прибыльность, обеспечивая более эффективную добычу по сравнению с другими пулами.',
            item2:
              'полная прозрачность: мы предоставляем детализированную отчетность и доступ к данным в реальном времени, чтобы вы всегда знали, как рассчитывается ваша прибыль.',
            item3:
              'доступное API и экспорт данных: интегрируйте наши сервисы с вашими системами и получайте данные в удобном для вас формате.',
            item4:
              'индивидуальная разработка: мы готовы адаптировать наши решения под любые потребности вашего бизнеса, предлагая гибкую разработку и интеграцию.',
          },
        },

        faq3: {
          title: 'Какой уровень доходности я могу ожидать, присоединившись к Neopool?',
          desc: 'Благодаря нашим передовым технологиям и уникальным алгоритмам, Neopool обеспечивает максимальную доходность для майнеров. Хотя конкретный уровень доходности зависит от вашего оборудования и текущих рыночных условий, наши пользователи обычно отмечают увеличение прибыли по сравнению с другими пулами. Мы также предлагаем индивидуальные условия по комиссии, что дополнительно повышает вашу прибыльность.',
        },

        faq4: {
          title: 'Как Neopool обеспечивает прозрачность и надежность выплат?',
          desc: 'Прозрачность и надежность — наши главные приоритеты. Мы обеспечиваем:',
          list: {
            item1:
              'ежедневные автоматические выплаты без комиссии: ваши заработки автоматически поступают на ваш счет каждый день без каких-либо скрытых платежей.',
            item2:
              'детализированная отчетность: вы можете отслеживать каждую добытую монету и начисления в реальном времени.',
            item3:
              'прозрачный механизм распределения доходов: мы открыто показываем, как рассчитывается ваша прибыль, обеспечивая полную ясность в расчетах.',
          },
        },

        faq5: {
          title: 'Какой размер комиссии берет Neopool?',
          desc: 'Размер комиссии в Neopool устанавливается индивидуально и зависит от объема вашего майнинга. Мы предлагаем гибкие и конкурентоспособные условия по комиссии для профессиональных клиентов, чтобы обеспечить вам максимальную прибыль. Для получения персонального предложения свяжитесь с нашей командой, и мы подберем оптимальные условия именно для вас.',
        },

        faq6: {
          title: 'Neopool подписывает блоки самостоятельно?',
          desc: 'Да, Neopool подписывает блоки самостоятельно. Это означает, что мы непосредственно участвуем в процессе создания новых блоков в блокчейне, используя собственную инфраструктуру и мощности. Это повышает эффективность майнинга и обеспечивает дополнительную безопасность для наших пользователей.',
        },

        faq7: {
          title: 'Как начать работу с Neopool?',
          desc1: 'Начать работать с Neopool просто:',
          list: {
            item1: 'регистрация: создайте аккаунт на нашем сайте Neopool.com.',
            item2:
              'настройка оборудования: следуйте нашим инструкциям или обратитесь к нашей поддержке для настройки вашего майнингового оборудования.',
            item3: 'подключение к пулу: используйте предоставленные нами настройки для подключения к Neopool.',
            item4: 'начало майнинга: запустите процесс майнинга и начните получать доход с максимальной эффективностью',
          },
          desc2: 'Если у вас возникнут вопросы на любом этапе, наша персональная поддержка готова помочь вам 24/7.',
        },

        faq8: {
          title: 'Предлагаете ли вы реферальные программы или бонусы для майнеров?',
          desc1: 'Да, мы ценим каждого нашего клиента и предлагаем:',
          list: {
            item1:
              'реферальную программу: приглашайте друзей и коллег и получайте бонусы за каждого нового пользователя, который начнет майнить с Neopool.',
            item2:
              'бонусы для новых пользователей: специальные предложения и акции для новых клиентов, чтобы вы могли убедиться в преимуществах работы с нами.',
            item3:
              'программы лояльности: для наших постоянных клиентов мы предоставляем дополнительные преимущества и индивидуальные условия сотрудничества.',
          },
          desc2: 'Следите за обновлениями на нашем сайте, чтобы не пропустить актуальные акции и предложения.',
        },
      },
    },

    signIn: {
      title: 'Вход',
      passwordRequired: 'Введите Ваш пароль',
      forgotPassword: 'Забыли пароль?',
      desc: 'Впервые здесь?',
      signUpLink: 'Зарегистрируйтесь',
    },

    signUp: {
      title: 'Регистрация',
      email: 'E-mail',
      agreement: 'Я принимаю условия <Link>пользовательского соглашения</Link>',
      agreementWarn: 'Пожалуйста, прочтите пользовательское соглашение и примите условия',
      signUpBtn: 'Зарегистрироваться',
      statusSuccess: 'Успешно',
      statusSuccessDesc: 'Мы отправили Вам письмо. Для продолжения регистрации необходимо перейти по ссылке в нем',
      desc: 'У Вас уже есть аккаунт?',
      logInLink: 'Войти',

      agreementModal: {
        header: {
          title: 'Dear user,',
          text1:
            'By clicking Agree and Continue, you hereby agree and consent to the User Agreement, its policies, and the Privacy Policy.',
          text2:
            'Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service',
        },
        document: {
          userAgreement: {
            title: 'Neopool Service User Agreement',
            text: {
              part1:
                'The purpose of this User Agreement (hereinafter the “Agreement”) is to ensure that the proper elements and commitments are in place to provide a consistent and mutually beneficial business relationship between the Miner and <strong>NEOPOOL LTD</strong> (collectively the “Parties”).<br/>The goal of this Agreement is to obtain mutual agreement for mining pool service between the Miner and <strong>NEOPOOL LTD</strong>.',
              part2: 'The objectives of this Agreement are to:',
              part3:
                'Provide clear reference to service ownership, accountability, roles and/or responsibilities<br/> Present a clear, concise and measurable description of service, operational, and compliance obligations between the parties.<br/>Match perceptions of expected service provision with actual service support & delivery.',
            },
          },

          termOfUse: {
            title: 'TERMS OF USE',
            text: {
              part1:
                'These Terms of Use (“Terms of Use”) constitute a legally binding agreement, whether personally or on behalf of an entity (“you”, “miner” or the “user”) and <strong>NEOPOOL LTD</strong>, a company duly incorporated and registered under the laws of Seychelles, Company number 240364, Registered Agent and address: Apollo Business Solutions (Pty) Ltd, 103 Sham Peng Tong Plaza, Victoria, Mahé, Seychelles ("Company").<br/>By accessing and using the Pool, Miner accepts and agrees to the Terms and Conditions, and the Company confirms its commitments to Miner (collectively, the “Service Agreement”). As the operator of the Pool, Neopool shall provide a mining Pool Service (as defined below) to Miner under the Service Agreement.',
              part2:
                'Miner agrees that the Company will have the right to modify the Terms of Use at any time, without notification to the Miner. Miner agrees to be solely responsible for reviewing the Terms of Use and/or any modifications thereto.',
              part3:
                'By registering as a User and/or submitting or providing information to the Company, you acknowledge that:',
              part4:
                'you have read, understood and agreed to be bound by all the provisions of the Terms of Use and the Privacy Policy, as may be amended from time to time; and that no promises or representations have been made to you to induce you to accept these terms.',
              part5:
                'If you do not agree with these Terms of Use, please stop using this Application immediately or any of the Company’s Services.',
            },
          },

          definitions: {
            title: '1. Definitions',
            text: {
              part1:
                'In these Terms of Use, unless the context otherwise requires, the following words and expressions shall have the following meanings:',
              part2:
                '<strong>“Agreement”</strong> means any agreement Miner have entered into with the Company for miner’s use of any Services;',
              part3:
                '<strong>“Application”</strong> means the Company website at https://www.neopool.com (and other subdomains of the main Company’s domain “neopool.com”) and the Company software application which may be downloaded by miner from platforms (such as Apple App Store and Google Play) as the Company may determine from time to time for use on your smartphone or other mobile devices;',
              part4:
                '<strong>“Confidential Information”</strong> means all information in any and all mediums disclosed by one party to another party including, without limitation, data, technology, know-how, inventions, discoveries, designs, processes, formulas, models, equipment, algorithms, software programs, interfaces, documents, specifications, information concerning research and development work, and/or trade and business secrets, current, planned or proposed products, marketing and business plans, forecasts, financial information, and shall include User Data;',
              part5:
                '<strong>"Force Majeure"</strong> means any event or circumstance, or any combination of events or circumstances, which are not attributable to the Company, including but not limited to malfunctions of the Internet or other telecommunications facilities, failures by (third) parties on which the Company depends when providing the Services, the defective condition of items, equipment, software, personal data, or other materials which you have instructed the Company to use and/or the non-availability of one or more members of the Company staff (due to illness, strikes or otherwise).',
              part6:
                '<strong>"Privacy Policy"</strong> means the statement that sets out the Company’s policy on collection, use and disclosure of personal data and which can be accessed at Privacy Policy;',
              part7:
                '<strong>“Service”</strong> means all or any of the Services provided by Company to Miner including but not limited to the services, content and transaction capabilities on the Application;',
              part8:
                '<strong>“User”</strong> means a user of the Application, whether an individual or a business entity, who has registered for an account through the Application;',
              part9:
                '<strong>“User Data”</strong> means information (including personal information) relating to a User, including but not limited to order information, payment information, account information, preferences and tendencies.',
            },
          },

          termsOfService: {
            title: '2. Neopool Terms of Service',
            text: {
              part1:
                'NEOPOOL LTD provides products, services, and technologies through Neopool Platform the (“Neopool Platform”). These terms of service constitute the entire agreement between the parties (together, the “Terms”). In the event of any inconsistency between these Terms and any of the other addenda, terms, and documents, the provisions of these terms of services shall prevail to the extent of such inconsistency. All other information provided on the Neopool Platform or verbal/written statements made are excluded from these Terms and do not constitute a legal agreement between the parties. Use of the Neopool Platform and the services is governed by these Terms.',
            },
          },

          AmlKycCtfPolicy: {
            title: 'AML / KYC / CTF policy',
            text: {
              part1:
                'This Know-your-Customer and Anti-money Laundering and counter-terrorist financing Policy and ("KYC/AML/CTF Policy") outlines the procedures and mechanisms used by Neopool to prevent money laundering and doing business with illicit counterparties.',
              part2:
                'Our KYC/AML/CTF Policy is guided by the Anti-Money Laundering and Countering the Finance of Terrorism Act 2020, including all of its amendments up to 2024, issued by the Seychelles Financial Intelligence Unit, specifically, the AML and Counter-Terrorist Financing (“CTF”) provisions, as mandated by other applicable directives and decrees, and all other rules and regulations regarding KYC, AML and CTF, as well as otherguidelines of the Federal Service Authority.',
              part3:
                'The Company adheres to Financial Action Task Force (FATF) Recommendations that are recognized as the international standard for combating money laundering and the financing of terrorism and proliferation of weapons of mass destruction.',
              part4:
                'The Company will request and collect all necessary KYC and due diligence documents for each Miner so as to enable the Company to full perform their duties and obligations under the Law, the Directives and the AML Law.',
              part5:
                'It is of critical importance for the Company’s integrity and reputation, to be able to identify, report, and take precautions to guard against money laundering and financing of terrorism. The nature of the Company’s business requires it to abide by anti-money laundering (AML) and countering the financing of terrorism (CFT) legislation and regulation that apply to its activities. In addition, the Company may be particularly attractive to individuals seeking to clean-up money due to non-face-to-face nature of the services.',
              part6:
                'In view of the above, You are not allowed to access or use the Neopool Platform if any part of your mining farm is located in, you are located in, incorporated or otherwise established in, or a resident of: (i) any country or other jurisdiction that are subject to sanctions that prohibit You from conducting business in accordance with these Terms, and those included in EU sanction list; (ii) a jurisdiction where it would be illegal according to Applicable Law for you (by reason of your nationality, domicile, citizenship, residence or otherwise) to access or use the Neopool Platform; or (iii) where the publication or availability of the Neopool Platform is prohibited or contrary to local law or regulation, or could subject any member of Neopool to any local registration or licensing requirements (together, the “Restricted Jurisdictions”). Neopool may, in its sole discretion, implement controls to restrict access to the Neopool Platform in any of the Restricted Jurisdictions. If Neopool determines that you are accessing the Neopool Platform from any Restricted Jurisdiction, or have given false representations as to your location of incorporation, establishment, citizenship or place of residence, Neopool reserves the right to close any of your accounts immediately and freeze all of your account balances.',
              part7:
                'You hereby authorize us, or a third-party service provider, to take any measures that we consider necessary to perform background checks on yourself and/or your institution. We screen individuals and institutions that are illicit actors, politically exposed, sanctioned, and/or have adverse media outstanding. Failing to comply with KYC/AML policies and background checks provides Neopool the right to close any of your accounts and/or freeze all of your account balances.',
              part8:
                'You hereby authorize us, or a third-party service provider, to take any measures that we consider necessary to monitor and screen your Bitcoin and other cryptocurrency address for the detection of illicit activity. We monitor miner payout addresses on an ongoing basis.',
              part9:
                'If at any point we deem your account to be associated with any illicit activity we will take the appropriate measures to close any of your accounts immediately and freeze all of your account balances and report suspicious transactions to the appropriate authority as required by Law.',
              part10:
                'The Parties will not, directly or indirectly, in connection with this Terms of use, offer, pay, promise to pay or authorize the giving of money or any other form of consideration to or for the benefit of (a) any governmental official, (b) any political party, (c) any nominee for public office, (d) any employee, officer or director of an enterprise that is directly or indirectly controlled by or is owned by a governmental body, or (e) any relative of any of the foregoing persons, while knowing or being aware of a high probability that all or a portion of such money or other consideration is, or will be used, for the purpose of influencing a governmental official in his or her official capacity, in order to induce a governmental official to do or omit to do an act in violation of his or her lawful duty or to secure an improper advantage in order to assist in obtaining or retaining business for or with, or directing business to, any person.',
              part11:
                'The Miner will defend, indemnify and hold the Company and its affiliates harmless from and against any and all claims, losses, damages, expenses and liabilities, including attorney’s fees, arising from its failure to comply with the provisions of the Terms of use. For the avoidance of doubt, the foregoing obligation to remedy losses applies to claims based on violations and alleged violations of the Convention on Combating Bribery of Foreign Officials in International Business Transactions (OECD Convention) and/or the national laws applicable to the relevant governmental official(s).',
              part12:
                'We require you not to share your password, give access to your Neopool account to others, or transfer your account to anyone else. Only individuals or institutions that have opened an account (“Neopool Account”) and successfully completed our KYC/AML/CTF checks are considered Neopool customers. If you would like to provide access to your Neopool Account to another individual or institution, this individual or institution must register a user account with Neopool and successfully complete our KYC/AML/CTF checks. You agree that you will not allow any persons who have not successfully completed our KYC/AML/CTF checks to access or use your Neopool Account.',
              part13:
                'All records shall be kept by the Company for a minimum of five (5) years which period runs from date of execution of the transaction or the termination of the business relationship as the case may be. This period may be extended in circumstances where the Company or relevant government authority is carrying on an investigation of a reported suspicious transaction.',
            },
          },

          services: {
            title: '3. Neopool Services',
            text: {
              part1:
                'Neopool uses its own system, through the Internet and other means to provide Miner with a digital currency mining Pool and other services/products that may be added based on the Pool site (“Service”).',
              part2:
                'Miner hereby authorizes Neopool to be fully responsible for payouts of the profit from such value-added Service',
              part3:
                'The Company shall not be held liable for any fault, omission or breach of contract of a third-party service provider.',
            },
          },

          minerRightsAndObligations: {
            title: '4. Miner Rights and Obligations',
            text: {
              part1:
                '<strong>a.</strong> Miner shall be responsible for preparing the necessary equipment and bear the expenses related to using such necessary equipment to participate in the Pool and Service.',
              part2:
                '<strong>b.</strong> Miner agrees to provide legal, true, accurate and detailed personal information, and update such information as needed.',
              part3:
                '<strong>c.</strong> Miner shall comply with all applicable laws, rules, and regulations related to the use of the Pool and Service.',
              part4:
                '<strong>d.</strong> Miner shall disclose the use of any proxies or agents that are configured between the Mining Pool and the ASIC. Company reserves the right to withhold Miners pending balance in the event a Miner has not configured their proxy or agent correctly.',
              part5:
                '<strong>e.</strong> Miner acknowledges and agrees that it is using the Pool and Service at its own risk.',
              part6:
                '<strong>f.</strong> In the event Miner’s access and/or rights to the Pool and Service have been discontinued, Miner is solely responsible for settling the remaining balances left in its account. Company shall use commercially reasonable efforts to assist Miner with settling any remaining balances in Miner’s account.',
              part7:
                '<strong>g.</strong> For the avoidance of doubt, Company shall not be responsible or liable to Miner for any balances remaining in Miner’s account three (3) months after Miner’s access and/or rights to the Pool and Service have been discontinued (regardless of whether the balances were left in Miner’s account intentionally).',
              part8:
                "<strong>h.</strong> It is the responsibility of Miner to ensure they have built redundancy into their mining operation to hedge against outages, system failures, and Force Majeure. As a Miner using the Pool and Service, Miner must configure a non-Neopool as backup/failover pool in all Miner machines pointed at Neopool stratums. In the event of a Pool outage that results in Pool downtime in excess of Neopool's SLA uptime guarantee of 98% monthly hashrate, financial compensation from Neopool to Miner for the outage will be capped at 8 hours of the FPPS value of Miner’s average total hashrate on a 144 block look-back basis, or, using the same compensation methodology, the value of lost hashrate accrued between Pool outage occurrence and the time Miner machines redirect their hashrate to the backup/fail-over pool, whichever is shorter.",
              part9:
                '<strong>i.</strong> Despite our trying to make our services broadly available to everyone, You agree that you cannot use Neopool if:<br/>- You carry out any activity that: (i) involves proceeds from any illegal or unlawful activity (including activities relating to cryptocurrency tumblers, darknet markets, money laundering or terrorism financing); (ii) publishes, distributes or disseminates any illegal or unlawful material or information; or (iii) otherwise violates, or could violate, any applicable laws;<br/>- Your legal capacity has not been limited to the extent that would prevent you from undertaking the obligations assumed by these Terms;<br/>- You are under the age of 18;<br/>- We previously disabled your account for violation of our Terms;<br/>- You are in Restricted Jurisdictions;<br/>- The legal entity you are acting on behalf is not duly established and existing under the law that governs its jurisdiction;<br/>- You are not authorized to act on behalf of the legal entity and to undertake these Terms.',
              part10: 'If you are acting on behalf of a legal entity, you acknowledge the following:',
              part11:
                '- Your legal capacity has not been limited to the extent that would prevent you from undertaking the obligations assumed by these Terms.',
              part12:
                'You acknowledge that you are fully responsible for obtaining necessary information about tax or similar obligations arising from the use of our services, products or software. You also acknowledge that we are not responsible for obtaining the mentioned information nor for the fulfillment of such tax (or similar) obligations.',
              part13:
                'You agree not to abuse or exploit by any means our services, products or software. This includes but is not limited to mistakes, programming bugs, withholding attacks, botnet attacks, Denial-of-Service attack (DDoS) or errors in a cryptocurrency network to take unfair advantage of us or other users in our platform in bad faith.',
              part14: 'The Company does not permit those under 18 to use the Service.',
              part15:
                '<strong>j.</strong> You are responsible for maintaining the confidentiality of any login information associated with any account you use to access the Services, and any Wallet backups, and any 2-factor authentication credentials. You are responsible for all activities that occur under the account(s) associated with you. You agree not to divulge your account details, including your password, to anyone. We cannot and will not be liable for any loss or damage from your failure to maintain the security of your account and password. If you become aware of any unauthorized use of your password or of your account, you agree to notify us immediately support@neopool.com.',
              part16:
                '<strong>k.</strong> You shall not access (or attempt to access) the Services by any means other than through the means we provide or have indicated to be used. You specifically agree not to access (or attempt to access) any Services via any illegal and unlawful means, or use any Services in an illegal or unlawful manner, or use any Services to support purposes that are illegal or unlawful (such as but not limited to terrorism, fraud, money laundering or other criminal or unlawful activity).',
            },
          },

          serviceChargesAndPayment: {
            title: '5. Service Charges and Payment',
            text: {
              part1: 'You shall pay all fees according to the terms set out in the applicable Agreement.',
              part2:
                'You acknowledge that with respect to several Services, each Service is only rendered after the relevant fee has been paid, as set out in the applicable Agreement.',
              part3:
                'The Company reserves the right to change the applicable fees, and the fee structure or arrangement under the Agreement at any time. Only if required under applicable law, the Company will notify you of any such change(s) in advance.',
              part4:
                'To the extent permitted by applicable law, your payment obligations are non-cancelable and non-refundable. You shall be required to pay, on behalf of the Company, any sales, value-added or other similar taxes payable by the Company based on the Services ordered. The Miner – individual person – is also obliged to pay all the relevant taxes, duties and levies as prescribed by the current legislation, on its own.',
              part5:
                'The Company shall be entitled to set-off any amount payable by the Company to you against any amount payable by you to the Company. You shall not be entitled to set-off any amount payable by you to the Company against any amount payable by the Company to you.',
              part6:
                "If you fail to pay any invoices timely, in addition to the Company's other remedies, the Company may suspend or terminate access to and use of the Services.",
              part7:
                '<strong>Fees</strong><br/>Miner will be paid for Bitcoin hashrate using a Full Pay Per Share (FPPS) payment methodology, defined as the expected value of both block reward and block transaction fees. Pool fees will be calculated based on the FPPS methodology.',
            },
          },

          confidentiality: {
            title: '6. Confidentiality',
            text: {
              part1:
                'Both Parties agree not to disclose any Confidential Information from the Pool and/or Service. “Information" includes (but is not limited to) information regarding Neopool’s Pool, Miner’s data, Service, Confidential documentation, software, trade secrets embodied therein and any other written or electronic information that is either (i) marked as confidential and/or proprietary, or which is accompanied by written notice that such information is confidential and/or proprietary, or (ii) not marked or accompanied by notice that it is confidential and/or proprietary but which, if disclosed to any third party, could reasonably and foreseeably cause competitive harm to the owner of such information. Confidential Information shall not include information which is: (i) publicly available, (ii) lawfully obtained by a party from third parties without restrictions on disclosure, or (iii) independently developed by a party without reference to or use of the Confidential Information.',
              part2:
                'All Confidential Information identified in accordance with the provisions of this clause shall remain the property of the disclosing party and shall be destroyed or returned to the disclosing party (at the selection of the disclosing party) upon written request. The disclosing party’s failure to request such return or destruction, shall not relieve the receiving party of its confidentiality and non-use obligations.',
              part3:
                'The parties acknowledge that for the purposes of data protection legislation (Data protection Bill, 2023, Seychelles (Bill No. 19 of 2023), General Data Protection Regulation (EU) 2016/679 (“GDPR”) or equivalent, and other applicable data protection laws and regulations), both parties are deemed to be separate Controllers in respect of the personal data of their contact persons and other personnel (where the Controller has the meaning defined in data protection legislation).',
              part4:
                "Each party, as a separate Controller, warrants that it will comply with all applicable requirements of the data protection legislation, as follows:<br/>it has the legal basis for the transfer of personal data of its contact persons to the other party, and for the processing of personal data of the other party's contact persons, obtained in the performance of this Agreement;",
              part5:
                "- it will inform the other party's contact persons about the processing of their personal data for the purposes of performing this Agreement;",
              part6:
                '- it will inform its contact persons under this Agreement about the transfer of their personal data to the other party for the purpose of performing this Agreement;',
              part7:
                "- it will acquaint its contact persons under this Agreement with the other party's Privacy Notice and send the other party a list of acquainted persons.",
            },
          },

          suspensionAndTermination: {
            title: '7. Suspension and Termination',
            text: {
              part1:
                'You hereby agree that the Company is entitled to suspend or to terminate your use of the Services immediately, without notice (unless notice is required under the applicable law), and for any reason including but not limited to:',
              part2:
                '<strong>(a)</strong> during planned or unplanned downtime for upgrades and maintenance to the Services in case of occurrence of a Force Majeure event;<br/><strong>(b)</strong> if the Company suspects or detects any malicious code, software or a security issue, connected to your Wallet or your use of the Services;<br/><strong>(c)</strong> if You breach any material term of: this Agreement, these Terms of Use, and/or any other agreement with the Company.',
              part3:
                'For the avoidance of doubt, subject to the aforesaid, if the Company is entitled to terminate your use of the Services, the Company shall terminate the application of these Terms of Use to you.<br/>The Company shall also be entitled to suspend or terminate your use of the Services in the manner set out in the applicable Agreement.',
              part4:
                'For the avoidance of doubt, the Company shall not be required to compensate you or reimburse or cover any cost incurred by you in relation to the termination or suspension of your use of the Services.',
              part5: 'Upon termination of the application of these Terms of Use to you:',
              part6:
                '<strong>(a)</strong> each Party shall return to the other Party, or destroy (and provide certification of such destruction), all property of the other Party in its possession or control (including all Confidential Information);<br/><strong>(b)</strong> all rights granted to you hereunder will immediately cease; and<br/><strong>(c)</strong> the Company will have the right but not the obligation to delete your account.',
            },
          },

          disclaimerOfWarranty: {
            title: '8. Disclaimer of warranty',
            text: {
              part1:
                'You acknowledge that your use of Neopool Platform is at your sole risk and discretion. All materials, information, products, Services, software and other content contained in Neopool Platform, or any materials that are obtained from a linked site is provided "as is" without warranty or conditions of any kind, whether express or implied (including but not limited to, the implied warranties or conditions of merchantability and fitness for a particular purpose, non-infringement, security or accuracy).',
              part2:
                'You assume total risk and responsibility for your use of the Neopool Platform and the Services provided herein, including the submission of any personal information (where applicable). Except as expressly stated in these Terms of Use, all warranties, conditions and other terms, whether express or implied, by statute, common law or otherwise are hereby excluded to the fullest extent permitted by law.',
              part3:
                'You acknowledge that some of the Services, as an internet-delivered software application, may experience periods of downtime, including but not limited to scheduled maintenance. The Company makes no representations or warranties, whether express, implied or statutory, with respect to the Application/Services provided hereunder, including the Wallet and any documentation, content, data and materials made available with the Services. We do not warrant or represent that your access to and/or use of the Neopool Platform and/or of any Service will be uninterrupted (whether due to any temporary and/or partial breakdown, repair, upgrade or maintenance of the Neopool Platform or otherwise) or error-free or that any information, data, content, software or other material accessible through the Application/Services will be free of bugs, viruses, worms, Trojan horses or other harmful components.',
              part4:
                'We specifically disclaim compatibility between the Neopool Platform and any software or hardware.',
              part5: 'Any content you download from our Application/Services is done so at your own discretion.',
              part6:
                'No information or advise, whether expressed implied, verbal or written, obtained by you from the Company, the Application or through any Services we provide, shall create any warranty, guarantee, or condition of any kind, except for those expressly outlined in these Terms of Use or in the Agreement.',
            },
          },

          indemnity: {
            title: '9. Indemnity',
            text: {
              part1:
                'By agreeing to the Terms of Use, you agree that you shall defend, indemnify and hold the Company, its licensors and each such party’s parent organizations, subsidiaries, affiliates, officers, directors, members, employees, attorneys and agents harmless from and against any and all claims, costs, damages, losses, liabilities and expenses (including legal fees and costs and/or regulatory action) arising out of or in connection with: (a) your use of the Service, and/or the Application in your dealings with third-party merchants, providers, partners, advertisers and/or sponsors, or (b) your violation or breach of any of the Terms of Use or any applicable law or regulation, whether or not referenced herein, or (c) your violation of any rights of any third party arranged via the Service, or (d) your use or misuse of the Service and/or the Application; save to the extent arising out of the fraud, negligence or willful misconduct of the Company.',
            },
          },

          nonExclusivity: {
            title: '10. Non-exclusivity',
            text: {
              part1:
                'Nothing in these Terms of Use is intended to create, nor shall it be construed as creating, any exclusive arrangement between the parties to these Terms of Use. These Terms of Use shall not restrict either Party from entering into similar arrangements with others, provided it does not breach its obligations under these Terms of Use by doing so, including without limitation, any confidentiality obligations. In particular, the Company shall be entitled to develop, acquire, license and to market and distribute materials, products or services which are competitive with your products or services, provided that the Company does not use your Confidential Information in so doing.',
            },
          },

          forceMajeure: {
            title: '11. Force Majeure',
            text: {
              part1:
                'Both Parties shall not be liable for any non-performance of its obligations pursuant to the Service Agreement if such non-performance is caused by a Force Majeure event. In case of a Force Majeure event, both Parties have the right to suspend or terminate its services immediately under the Service Agreement. “Force Majeure” events shall mean any event or circumstance, or any combination of events or circumstances which are beyond the control of the Parties. Such events or circumstances shall include, but are not limited to events or occurrences that delay, prevent or hinder the Parties from performing such obligations, cyberattacks, acts of God, and regulatory, administrative or similar action or delays to take actions of any governmental authority.',
              part2:
                'On the occurrence of any event of Force Majeure the Party so affected shall as soon as reasonably possible: give a written notice of such event to the other Party by letter, telex or fax and resume full performance of its obligations under the Contract as soon as possible, and so notify the other Party thereof.',
              part3:
                'If, due to a Force Majeure event, one or the other Party could not accomplish its contractual obligations during a period of more than three (3) calendar months, the Parties shall meet and find an agreement within the shortest possible time upon the conditions on which they could continue the execution of the Contract. It is agreed that during the time of suspension due to the force majeure event, each Party shall bear its own costs',
            },
          },

          thirdPartyRights: {
            title: '12. Third Party Rights',
            text: {
              part1:
                'All the terms and provisions of these Terms of Use shall be binding upon and inure to the benefit of the parties to these Terms of Use and to their respective heirs, successors, permitted assigns and legal representatives. The Company shall be permitted to assign these Terms of Use without notice to or consent from you. You shall have no right to assign or otherwise transfer these Terms of Use, or any of its rights or obligations hereunder, to any third party without the Company’s prior written consent, to be given or withheld in the Company’s sole discretion.',
            },
          },

          severabilityAndWaiver: {
            title: '13. Severability and Waiver',
            text: {
              part1:
                'No waiver by us of any of these Terms of Use or default of yours under these Terms of Use shall operate or be construed as a waiver by us of any future defaults or provision.',
              part2:
                'If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.',
              part3:
                "Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.",
              part4:
                'These Terms and Conditions are translated into Russian. You agree that the original English text shall prevail in the case of a dispute.',
            },
          },

          entireAgreement: {
            title: '14. Entire agreement',
            text: {
              part1:
                'These Terms of Use contains all the terms agreed between the parties regarding its subject matter and supersedes and excludes any prior agreement, understanding or arrangement between the parties, whether verbal or in writing.',
            },
          },

          governingLawAndDisputeResolution: {
            title: '15. Governing Law and Dispute Resolution',
            text: {
              part1:
                'This Agreement shall be governed by and construed in accordance with the laws of Seychelles, without giving effect to any choice of law or conflict of law provision or rule that would cause the application of the laws of any jurisdiction other than Seychelles. The Parties agree to submit to the exclusive jurisdiction of the courts located in Seychelles for any disputes arising out of or relating to this Agreement.',
              part2:
                'In the event of any dispute or difference arising out of or in connection with or in relation to these Terms of Use, including any question regarding the existence, validity, application or interpretation of these Terms of Use or any of its provisions, both parties shall endeavor to settle the dispute informally by agreement between the parties. Both parties shall always act in good faith and co-operate with each other to resolve any disputes.',
            },
          },

          intellectualProperty: {
            title: '16. Intellectual Property',
            text: {
              part1:
                'The Service and its original content (excluding Content provided by You or other users), features and functionality are and will remain the exclusive property of the Company and its licensors.',
              part2:
                'The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries.',
            },
          },

          linksToOtherWebsites: {
            title: '17. Links to Other Websites',
            text: {
              part1:
                'Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.',
              part2:
                'The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.',
              part3:
                'We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.',
            },
          },

          yourFeedbackToUs: {
            title: '18. Your Feedback to Us',
            text: {
              part1:
                'You assign all rights, title and interest in any Feedback You provide the Company. If for any reason such assignment is ineffective, You agree to grant the Company a non-exclusive, perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit such Feedback without restriction.',
            },
          },
        },
      },
    },

    registration: {
      title: 'Регистрация',
      wrongEmailTitle: 'Ссылка недействительна либо устарела',
      wrongEmailSubTitle: 'Если Вам нужна помощь при регистрации, пожалуйста, свяжитесь с командой поддержки',
    },

    forgotPassword: {
      title: 'Сброс пароля',
      email: 'E-mail',
      statusSuccess: 'Успешно',
      statusSuccessDesc: 'Мы отправили Вам письмо со ссылкой. Для сброса пароля, пожалуйста, перейдите по ссылке.',
    },

    newPassword: {
      title: 'Сброс пароля',
      statusSuccess: 'Успешно',
      successDesc: 'Пароль изменен',
      statusError: 'Ошибка',
      errorDesc:
        'Ссылка недействительна либо устарела. Если Вам нужна помощь со сменой пароля, пожалуйста, свяжитесь с командой поддержки',
    },

    dashboard: {
      title: 'Главная',
    },

    profitDetails: {
      title: 'Данные о начислениях',
      desc: 'Доступны данные за 12 месяцев',

      tableHeaders: {
        date: 'Дата (UTC)',
        hashrate: 'Хэшрейт',
        rejectRate: 'Процент отклоненных шар',
        totalEarning: 'Всего начислено',
        unitOutput: '24ч доходность (на TH/s)',
        ppsProfit: 'Доход по PPS',
        pplnsProfit: 'Доход по PPLNS',
        soloProfit: 'Доход по Solo',
        actions: 'Действия',
      },
    },

    revenueHistory: {
      title: 'Вывод средств',

      tableHeaders: {
        time: 'Время вывода',
        type: 'Тип операции',
        miningAccount: 'Аккаунт',
        miningProfit: 'Доход',
        recipientAccount: 'Получатель',
        proportionOfSharing: 'Сумма вывода',
        amount: 'Баланс',
      },
    },

    afterSaleService: {
      title: 'Поддержка пользователей',
      emailTitle: 'E-mail',
      emailValue: 'support@neopool.com',
      telegramTitle: 'Telegram',
      telegramValue: 'neopoolcom',
    },

    accountInfo: {
      title: 'Баланс',
      profit: 'Начислено за последние сутки ({{coin}})',
      totalEarnings: 'Всего начислено ({{coin}})',
      accountBalance: 'Баланс аккаунта ({{coin}})',
      myBills: 'Операции по счету',
    },

    hashrate: {
      title: 'Хэшрейт',
      tenMinAvg: 'Ср. за 10 мин',
      hourAvg: 'Ср. за 1 час',
      dayAvg: 'Ср. за 1 день',
    },

    worker: {
      title: 'Устройства',
      seeAll: 'Подробнее',
      active: 'Активны',
      inactive: 'Неактивны',
      offline: 'Оффлайн',

      logs: {
        title: 'Логи',

        status: {
          initialized: 'Инициализирован',
          pending: 'Обрабатывается',
          completed: 'Выполнен',
          failed: 'Отклонен',
        },
      },
    },

    networkSummary: {
      title: 'Статистика сети Bitcoin',
      bitcoinPrice: 'Цена',
      networkHashrate: 'Мощность',
      networkDifficulty: 'Сложность',
    },

    accountsSummary: {
      title: 'Статистика аккаунтов',
      hashrate24h: 'Все аккаунты. Мощность (24 ч)',
      hashrate10m: 'Мощность (10 мин)',
      workers: 'Все аккаунты. Устройства',
      balance: 'Все аккаунты. Баланс',
      activeWorkers: 'Активные устройства',
      inactiveWorkers: 'Неактивные устройства',
      offlineWorkers: 'Оффлайн устройства',
    },

    profitChart: {
      title: 'Начисления',
      legendName: 'Начислено за сутки',
    },

    hashrateChart: {
      title: 'График хэшрейта',

      legend: {
        hashRate: 'Хэшрейт',
        rejectRate: 'Процент отклоненных шар',
      },

      tooltip: {
        hashRate: 'Хэшрейт',
        rejectRate: 'Отклонено шар',
      },

      threeHours: '3 часа',
      oneDay: '1 день',
      sevenDays: '7 дней',
      sixMonths: '6 месяцев',
      oneMin: '1 мин',
      tenMin: '10 мин',
      oneHour: '1 час',
    },

    accountSettings: {
      basicInfo: {
        title: 'Личная информация',
        newMobile: 'Новый номер телефона',
        newEmailLabel: 'Новый e-mail',
        mobileRequestSuccess: 'Успешно. Телефон изменён.',
        emailRequestSuccess: 'Успешно. e-mail изменён',
      },

      accountSecurity: {
        title: 'Безопасность аккаунта',
        authenticator: 'Двухфакторная аутентификация',
        signInPassword: 'Пароль для входа в приложение',
        paymentPassword: 'Пароль для выплат',
        enableBtn: 'Включить',
        disableBtn: 'Отключить',
        successClipBoard: 'Одноразовые коды скопированы',
        errorClipBoard: 'Одноразовые коды не скопированы. Обновите страницу и попробуйте снова.',
        clipBoardBtn: 'Скопировать одноразовые коды',
        passwordChangeSuccess: 'Успешно. Пароль изменён.',

        form: {
          recoveryCodes:
            'Это Ваши коды для восстановления доступа, которыми можно будет воспользоваться в качестве одноразовой замены кода Google Authenticator, в случае потери доступа к приложению. Пожалуйста, храните эти коды в секрете, на бумаге либо на другом устройстве. Каждый код может быть использован только один раз.',
          successEnableMessage: 'Успешно. Двухфакторная аутентификация включена.',
          successDisableMessage: 'Успешно. Двухфакторная аутентификация выключена.',

          twoFAEnable: {
            title: 'Включение двухфакторной аутентификации',

            steps: {
              downloadApp: 'Загрузите приложение',
              securityCheck: 'Привяжите аккаунт',
              boundSuccessfulley: 'Готово',
            },

            formSteps: {
              downloadApp: 'Если у Вас нет приложения Google Authenticator - установите его.',
              scanBarcode: 'Откройте приложение Google Authenticator и отсканируйте QR-код:',
              enterKey: 'Если не получается отканировать, введите ключ:',
              enterCode: 'Введите код из приложения и нажмите кнопку "Готово" для завершения настройки.',
            },

            button: {
              unableToScan: 'Не получается отсканировать?',
              scanQrCode: 'Отсканировать QR-код?',
            },
          },

          twoFADisable: {
            title: 'Отключение двухфакторной аутентификации',
          },

          paymentPassword: {
            setUp: {
              title: 'Задание пароля для выплат',
            },

            change: {
              title: 'Смена пароля для выплат',
              warning:
                'Пожалуйста, учтите, что после смены пароля для выплат все переводы и выплаты будут приостановлены на 24 часа.',
            },

            steps: {
              setUpPassword: 'Установка пароля',
              securityCheck: 'Верификация',
              completed: 'Готово',
            },

            tooltip: {
              activation:
                'Пожалуйста, учтите, что после смены пароля для выплат все переводы и выплаты будут приостановлены на 24 часа.',
            },
          },
        },
      },

      signInHistory: {
        title: 'История входов на сайт',
        disclaimer: 'Если Вы не совершали какое-либо из этих действий - смените свой пароль немедленно.',
        headers: {
          singInTime: 'Время входа',
          location: 'Местоположение',
          ip: 'IP',
          browser: 'Браузер',
          device: 'Устройство',
          status: {
            title: 'Статус',
            success: 'Успешно',
            failure: 'Не успешно',
          },
        },
      },
    },

    workersPage: {
      myGroupTitle: 'Мои группы',
      createGrpBtn: 'Создать группу',
      manageGrpBtn: 'Управление группами',
      workersTitle: 'Список устройств',
      searchPlaceholder: 'Введите название устройства',

      filters: {
        all: 'Все',
        active: 'Активные',
        offline: 'Недоступные',
        inactive: 'Неактивные',
      },

      tooltip: {
        active: 'Получена хотя бы одна шара от устройства за последний час',
        inactive: 'Ни одной шары от устройства более 1 часа',
        offline: 'Ни одной шары от устройства более 24 часов',
      },

      emptyName: 'Имя отсутствует',

      tableHeaders: {
        workerName: 'Устройство',
        h10m: 'Ср. за 10 мин',
        h1h: 'Ср. за 1 час',
        h1d: 'Ср. за 1 день',
        rejectRate: 'Отклонено шар',
        lastSubmit: 'Последняя отправка шары',

        status: {
          title: 'Статус',
          active: 'Активно',
          inactive: 'Неактивно',
          offline: 'Недоступно',
        },

        operation: 'Функционирование',
      },

      modal: {
        removeWorkers: {
          title: 'Удалить устройства',
          desc: 'Вы уверены, что хотите навсегда удалить недоступные устройства? Количество выбранных устройств: {{count}}.',
          note: 'Когда устройство снова начнет майнить на Neopool, его данные снова появятся на этой странице.',
        },
      },

      configGuide: {
        title: 'Руководство по настройке устройства',

        instructions: {
          connectToLan: {
            title: 'Подключите',
            desc: 'Подключите устройство к сети LAN-кабелем, и подключите компьютер к той же сети. Найдите IP устройства. Откройте страницу конфигурации майнера на компьютере.',
          },

          minerConfig: {
            title: 'Настройте',
            desc: 'Введите основной и резервный URL Стратум-серверов (скопируйте из примера настройки). Введите имя устройства: оно должно состоять из цифр и строчных букв (латиница), не более 64 символов, и отделяться от имени аккаунта точкой. Можно использовать имя устройства из примера настройки. Выберите пароль (необязательно)',
          },

          viewHashrate: {
            title: 'Наблюдайте',
            desc: 'Сохраните настройки. Зайдите на страницу устройств и проверьте, начало ли работу Ваше новое устройство. Оно автоматически отобразится на этой странице в течение нескольких минут.',
          },
        },

        config: {
          title: 'Пример настройки устройства',

          columns: {
            pool: 'URL сервера {{index}}',
            worker: 'Имя устройства',
            password: 'Пароль',
          },

          otherPoolStratum: 'Добавьте ещё один стратум стороннего пула, как резервный',

          button: {
            copyHostingInfo: 'Скопировать информацию о хостинге: имя устройства + URL сервера.',
          },
        },
      },
    },

    miningAccounts: {
      title: 'Майнинг аккаунты',
      createSubAccTitle: 'Создать суб-аккаунт',
      accInputName: 'Название суб-аккаунта',
      hiddenAccCheckbox: 'Показать скрытые аккаунты',
      createSubAccBtn: 'Создать суб-аккаунт',
      manageSubAccBtn: 'Управление суб-аккаунтами',
      createSuccess: 'Суб-аккаунт успешно создан.',
      hideConfirmTitle: 'Вы уверены, что хотите скрыть аккаунт?',
      hideErrorTitle: 'Аккаунт невозможно скрыть',
      hideConfirm: 'Это действие скроет аккаунт навсегда. Его невозможно отменить.',
      hideAccountTootltip: 'Для управления суб-аккаунтами переключитесь на главный аккаунт',

      tableHeaders: {
        account: 'Аккаунт',
        allAccounts: 'Все аккаунты',
        revenue: 'Доход, {{coin}}',
        total: 'Всего',
        balance: 'Баланс',
        minerStatus: 'Статус устройств',
        online: 'Активные',
        offline: 'Недоступные',
        inactive: 'Неактивные',
        hashrate: 'Хэшрейт',
        day: '24ч',
        note: 'Описание',
        actions: 'Действия',
      },

      hideModal: {
        message: {
          activeWorkers: {
            title: '{{value}} устройств активны (или недавно были активны) на этом аккаунте.',
            desc: 'Пожалуйста, перед тем, как скроете аккаунт, настройте устройства для работы с другим аккаунтом.',
          },
          notEmptyBalance: {
            title: 'Текущий баланс аккаунта составляет {{value}}.',
            desc: 'Пожалуйста, перед тем, как скроете аккаунт, переведите все средства на другой аккаунт.',
          },
          watcherLinks: {
            title: 'У Вас имеются наблюдательные ссылки для данного аккаунта.',
            desc: 'Пожалуйста, учтите, что после того, как аккаунт будет скрыт, эти ссылки станут недействительными.',
          },
          autoWithdrawal: {
            title: 'У Вас настроены автовыплаты / автопереводы с участием данного аккаунта.',
            desc: 'Пожалуйста, учтите, что после того, как аккаунт будет скрыт, эти настройки станут недействительными.',
          },
        },
      },

      modal: {
        editAccount: {
          title: 'Редактировать аккаунт',
        },
      },
    },

    paymentSettings: {
      modal: {
        modifyPayment: {
          title: 'Изменить минимальную сумму автоматических выплат',
          label: {
            minAmount: 'Минимальная сумма',
            currentPayment: 'Установленная сумма',
            activation: 'Активация автовыплат',
          },

          steps: {
            withdrawalThreshold: 'Минимальная сумма автовыплат',
            auth: 'Авторизация',
            completed: 'Готово',
          },
        },

        withdrawal: {
          title: 'Вывод средств',

          steps: {
            withdrawal: 'Вывод средств',
            auth: 'Авторизация',
            completed: 'Готово',
          },

          rules: {
            rule1:
              'Для переводов между пользователями установлены НУЛЕВАЯ комиссия и отсутствует ограничение по сумме, а также не требуется подтверждение. Доступны переводы от {{unit}}.',
            rule2:
              'Выплата на кошелек {{coin}} облагается комиссией. Комиссия рассчитывается непосредственно перед выводом средств и может существенно колебаться в зависимости от состояния сети {{coin}}.',
            rule3: 'Выплата свыше {{manualAuditAmount}} {{coin}} требует ручного подтверждения (занимает до 24 часов).',
            rule4:
              'В настоящее время поддерживаются выплаты только на кошельки {{coins}}, пожалуйста, проверьте правильность адреса для выплаты.',
          },

          form: {
            label: {
              withdrawalType: 'Тип вывода средств',
              withdrawalAddress: 'Адрес кошелька для выплаты',
              accountName: 'Название аккаунта',
              amount: 'Сумма',
            },

            radio: {
              withdrawalType: {
                interUserTransfer: 'Внутренний перевод',
                normalTransfer: 'Выплата',
                fee: 'Комиссия {{fee}} {{coin}}',
              },
            },

            button: {
              withdraw: 'Выплатить',
            },
          },
        },

        internalTransfer: {
          title: 'Настройки внутренних переводов',

          form: {
            label: {
              accountTo: 'Перевести на',
              accountFrom: 'Перевести с',
              accounts: 'аккаунтов',
              activation: 'Активация переводов',
            },
          },

          label: {
            unavailable: 'Автоперевод с данного аккаунта уже настроен на другом аккаунте',
            value: 'с <accent>{{accountCount}} аккаунтов</accent> - на <accent>{{accountName}}</accent>',
          },

          steps: {
            internalTransfer: 'Внутренний перевод',
            auth: 'Авторизация',
            completed: 'Готово',
          },
        },
      },

      switcher: {
        error: {
          noData: {
            title: 'Ошибка',
            desc: 'Внутренний перевод не может быть активирован',
          },

          accountAlreadyConfigured: {
            title: 'Активация невозможна',
            desc: 'Автоперевод с данного аккаунта уже настроен на другом аккаунте',
          },

          noWallet: {
            title: 'Кошелёк отсутствует',
            desc: 'Для активации автовыплат необходимо добавить Ваш BTC адрес',
          },
        },
      },

      tooltip: {
        autoWithdrawal:
          'Автоматические выплаты производятся каждый день в промежутке {{time}} (UTC) и выполняются БЕСПЛАТНО. Текущая минимальная сумма выплат:  0.001 BTC. Автоматическая выплата осуществляется после того, как баланс аккаунта достигнет установленной Вами пороговой суммы.',

        internalTransfer:
          'Автоматические внутренние переводы производятся раз в сутки и выполняются бесплатно. Минимальная сумма не установлена.',
      },
    },

    miningSettings: {
      // miningServers: {
      //   table: {
      //     title: 'Mining Servers',
      //     column: {
      //       poolType: 'Pool Type',
      //       switchAddress: 'Pool Url',
      //       youAreMining: 'You’re mining',
      //     },
      //   },
      // },

      hashrateFluctuationNotification: {
        table: {
          title: 'Оповещение о колебаниях хэшрейта',
          column: {
            coinType: 'Монеты',
            thresholds: 'Порог оповещения',
            fluctation: 'Колебания',
            activeWorkers: 'Количество активных устройств',
            actions: '',
          },

          field: {
            thresholds: 'Порог оповещения',
            fluctuation: 'Колебания',
            activeWorkers: 'Кол-во активных устройств',
          },
        },

        tooltip: {
          fluct:
            'Колебания хэшрейта - на сколько процентов средний хэшрейт за последние 10 минут снижается по сравнению со средним хэшрейтом за сутки. Чтобы количество ложноположительных уведомлений было минимальным, рекомендуем устанавливать порог колебаний для уведомлений 25%.',
        },

        modal: {
          notificationSettings: {
            title: 'Настройка оповещений',

            steps: {
              notifications: 'Оповещений',
              frequency: 'Частота',
              email: 'E-mail',
              telegram: 'Telegram',
            },
          },
        },

        form: {
          label: {
            frequency: 'Частота',
            email: 'E-mail',
          },
        },

        info: {
          account: {
            title: 'Аккаунт',
          },

          frequency: {
            title: 'Частота',
            value: 'Интервал оповещений, не менее: {{interval}} часов',
          },

          emails: {
            title: 'E-mail',
          },

          phone: {
            title: 'Телефон',
          },

          telegram: {
            title: 'Telegram',
          },
        },

        progress: {
          title: 'Ход настройки уведомлений',
          description: 'Для получения уведомлений необходимо настроить все параметры',

          items: {
            frequency: 'Частота',
            emailOrTelegram: 'E-mail или Telegram',
          },

          tooltip: {
            frequency: 'Чтобы получать уведомления, настройте частоту',
            emailOrTelegram: 'Чтобы получать уведомления, настройте e-mail или telegram',
          },
        },
      },
    },

    watcher: {
      mySharing: {
        title: 'Мои ссылки наблюдения',
        desc: 'По ссылке наблюдения любой человек может увидеть данные Вашего аккаунта, но не сможет внести изменения.',
        createBtn: 'Создать ссылку',

        table: {
          miningAccount: 'Майнинг аккаунт',
          url: 'URL',
          Remark: 'Описание',
        },

        modal: {
          create: {
            title: 'Создать ссылку',
          },

          delete: {
            title: 'Вы уверены, что хотите удалить ссылку?',
            description:
              'Это действие удалит ссылку наблюдения навсегда. Его невозможно отменить. После удаления ссылки пользователи, с которыми Вы поделились этой ссылкой, больше не будут иметь доступ к данным Вашего аккаунта.',
            confirmBtn: 'Подтвердить',
            cancelBtn: 'Отмена',
          },

          permissions: {
            title: 'Настройки разрешений',
          },
        },

        notification: {
          delete: {
            success: 'Ссылка удалена успешно',
            error: 'Невозможно удалить ссылку. Попробуйте позже.',
          },

          create: {
            success: 'Ссылка создана успешно',
            error: 'Невозможно создать ссылку. Попробуйте позже.',
          },
        },

        createSteps: {
          create: 'Создание ссылки',
          auth: 'Авторизация',
          completed: 'Готово',
        },

        createForm: {
          fields: {
            gaCode: 'Код Google Authenticator',
            watcherType: 'Тип ссылки',
            miningAccount: 'Суб-аккаунт',
            note: 'Краткое описание',
            permissionSettings: 'Настройки разрешений',
          },

          btn: {
            confirm: 'Подтвердить',
          },
        },
      },

      // favorites: {
      //   title: 'Favorites',
      //   desc:
      //     "You can view other's account information from shared" +
      //     ' Watcher URL but have no authority for modification.',
      //   createBtn: 'Add',
      //   manageBtn: 'Manage Favorites',
      //   table: {
      //     miningAccount: 'Mining Account',
      //     url: 'URL',
      //     Remark: 'Remark',
      //   },
      // },

      // externalFavorites: {
      //   title: 'External Link Favorites',
      //   desc:
      //     'To facilitate your management of Watchers,' +
      //     ' you can add Watcher URLs of other mining pools to this Favorites.',
      //   createBtn: 'Add',
      //   manageBtn: 'Manage Favorites',
      //   table: {
      //     url: 'URL',
      //     Remark: 'Remark',
      //   },
      // },

      errors: {
        emptyAccessKey: {
          title: 'Нет ключа доступа',
          subTitle: 'Ссылка наблюдения без ключа доступа недействительна. Попробуйте использовать другую ссылку.',
        },

        invalidWatcherLink: {
          title: 'Ссылка недействительна',
          subTitle: 'Ссылка наблюдения недействительна, устарела или удалена. Попробуйте использовать другую ссылку.',
        },

        protectedWatcherLinkPath: {
          title: 'Недоступно',
          subTitle: 'Доступ к этой странице по данной ссылке наблюдения не разрешен',
        },
      },

      watcherLink: {
        successClipBoard: 'Ссылка скопирована в буфер обмена',
        errorClipBoard: 'Ссылка не скопирована. Обновите страницу и попробуйте снова.',

        types: {
          independent: 'Ссылка для суб-аккаунта',
          main: 'Основная ссылка (с доступом к данным всех Ваших аккаунтов)',
        },
      },

      permissions: {
        dashboard: 'Мониторинг',
        workers: 'Устройства',
        earnings: 'Доход',
        assets: 'Активы',
      },
    },

    notificationsPage: {
      label: {
        announcement: 'Новости и объявления (на e-mail)',
        autoWithdrawal: 'Оповещения об автовыплатах (на e-mail)',
        dailyMiningProfits: 'Ежедневный отчет о доходах за майнинг (на e-mail)',
        monthlyMiningProfits: 'Ежемесячный отчет о доходах за майнинг (на e-mail)',
      },

      tooltip: {
        announcement: 'Уведомления будут приходить на Ваш основной e-mail',
      },
    },

    apiManagement: {
      table: {
        title: 'Управление API',
        desc: 'Секретный ключ API - это эквивалент пароля к Вашему API-ключу. Пожалуйста, храните его в секрете.',

        columns: {
          apiKey: 'Ключ API',
          accessLevel: 'Уровень доступа',
          note: 'Описание',
          actions: 'Действия',
        },

        cells: {
          ipAddresses: 'IP-адресов: {{count}}',
          sameAsMain: 'Как в основном аккаунте',
        },
      },

      error: {
        disabled: {
          title: 'Управление API недоступно',
          desc: 'Если Вам нужна помощь, пожалуйста, свяжитесь с командой поддержки',
        },
      },

      actions: {
        create: 'Создать ключ API',
      },

      modal: {
        editApiKey: {
          title: 'Редактирование ключа API',

          steps: {
            apiKey: 'Ключ API',
            auth: 'Авторизация',
            completed: 'Готово',
          },
        },

        removeApiKey: {
          title: 'Удаление ключа API',
          desc: 'Вы уверены, что хотите удалить этот ключ API?',
        },

        checkSecretKey: {
          title: 'Просмотр секретного ключа',

          steps: {
            auth: 'Авторизация',
            secretKey: 'Секретный ключ',
          },

          labels: {
            secretKey: 'Секретный ключ',
            apiKey: 'Ключ API',
          },
        },

        editIpWhitelist: {
          title: 'Настроить разрешенные адреса',

          steps: {
            auth: 'Авторизация',
            setUp: 'Настройка разрешенных адресов',
            completed: 'Готово',
          },

          placeholder: {
            ipAddress: 'Введите IP-адрес...',
          },

          whitelistTypes: {
            inheritMain: 'Использовать список IP основного аккаунта',
            custom: 'Собственный список IP адресов',
          },
        },

        showIpWhitelist: {
          title: 'Pазрешенные адреса',
        },
      },

      accessLevel: {
        public: 'Публичный',
        private: 'Приватный',
      },

      disclaimer:
        '* По умолчанию разрешенные IP-адреса субаккаунтов устанавливаются такие же, как и для основного аккаунта. После этого Вы можете редактировать список разрешенных IP-адресов для каждого аккаунта в отдельности.',
    },

    referralRewards: {
      banner: {
        title: 'Порекомендуйте нас друзьям и вместе получайте награды!',

        section: {
          general: {
            title: 'Общие условия',
            ratio: 'Вознаграждение - 10%',
            validity: 'Длительность 12 месяцев',
          },

          ambassador: {
            title: 'Амбассадор',
            ratio: 'Вознаграждение - 20%',
            validity: 'Бессрочно',
          },
        },

        button: {
          apply: 'Применить',
        },

        description: {
          registeredRefereeGet: 'Ваш друг получит ',
          time: '30-дневную ',
          couponWith: 'скидку ',
          fee: 'на комиссию 50%',
        },
      },

      referralLinkInfo: {
        referralLink: 'Ваша реферальная ссылка',
        shareTo: 'Поделиться',
      },

      overallReferral: {
        title: 'Основные данные',
        param: {
          overallReferral: 'Все рефералы',
          cumulativeReferredAccounts: 'Активные рефералы (за 30 дней)',
          cumulativeReferralReward: 'Суммарное реферальное вознаграждение',
        },
      },

      table: {
        myReferredUsers: {
          title: 'Мои рефералы',
          columns: {
            referredAccounts: 'Основной аккаунт реферала',
            registrationDate: 'Дата регистрации',
            subAccountsNumber: 'Количество суб-аккаунтов',
          },
        },

        myEffectiveReferrals: {
          title: 'Мои активные рефералы',
          columns: {
            referredAccounts: 'Основной аккаунт реферала',
            registrationDate: 'Дата регистрации',
            subAccountsNumber: 'Количество суб-аккаунтов',
          },
        },

        myReferralRecords: {
          title: 'Мои реферальные вознаграждения',
          desc: 'Доступны данные за 6 месяцев',

          columns: {
            date: 'Дата (UTC)',
            account: 'Аккаунт',
            cumulativeRewards: 'Суммарное вознаграждение',
          },
        },

        myBeneficiaryRewards: {
          title: 'Мои партнёрские вознаграждения',
          desc: 'Доступны данные за 6 месяцев',

          columns: {
            date: 'Дата (UTC)',
            account: 'Аккаунт',
            amount: 'Суммарное вознаграждение',
          },
        },
      },
    },

    vipApplication: {
      section: {
        desc: {
          title: 'Заявка на VIP - условия',
          description: {
            part1: 'Пользователи, располагающие большим хэшрейтом могут получить VIP-статус ',
            part2:
              'VIP-пользователям доступны привилегии: особые тарифы и выделенные каналы подключения, для более высокого и стабильного дохода',
          },
        },
      },

      disclaimer:
        'Пользователи, располагающие вычислительной мощностью от 1 PH/s, могут отправить заявку на получение VIP-статуса. Пожалуйста, предоставьте нам данные о Вашем текущем хэшрейте, а также другую важную информацию. Мы свяжемся с Вами в ближайшее время.',
      button: 'VIP-условия для высокого хешрейта',
    },

    betaTestApplication: {
      section: {
        desc: {
          title: 'Заявка на Beta-тестирование',
          description: {
            part1: 'Уникальная возможность протестировать новый майнинг пул с нулевой комиссией.',
          },
        },
      },

      button: 'Оставить заявку',
    },

    myBills: {
      table: {
        title: 'Операции по счету',
        desc: 'Доступны данные за 1 месяц',

        filter: {
          type: {
            label: 'Тип',

            values: {
              all: 'Все',
              withdrawal: 'Выплаты',
              autoWithdrawal: 'Автовыплаты',
              miningProfit: 'Начисления за майнинг',
              referralReward: 'Реферальные вознаграждения',
              customReferralReward: 'Партнерские вознаграждения',
              internalTransfer: 'Внутренние переводы',
              autoInternalTransfer: 'Внутренние авто-переводы',
              systemUpdate: 'Системные обновления',
            },
          },

          coinType: {
            label: 'Coin Type',
          },
        },

        columns: {
          time: 'Время (UTC)',
          coinType: 'Монета',
          type: 'Тип',
          valueChange: 'Сумма',
          balance: 'Баланс',
        },

        columnValues: {
          type: {
            withdrawal: 'Выплаты',
            autoWithdrawal: 'Автовыплаты',
            interUserTransfer: 'Внутренние переводы',
            internalTransfer: 'Внутренние авто-переводы',
            PPS: 'FPPS',
            PPLNS: 'FPPS',
            referralReward: 'Реферальные вознаграждения',
            customReferralReward: 'Партнерские вознаграждения',
            systemUpdate: 'Системные обновления',
            transactionFreeze: 'Замороженные транзакции',
            autoWithdrawalFailed: 'Автовыплаты - отклонена',
            autoWithdrawalFreeze: 'Автовыплаты - в обработке',
            autoWithdrawalRejected: 'Автовыплаты - возврат',
            withdrawalFailed: 'Выплаты - отклонена',
            withdrawalFreeze: 'Выплаты - в обработке',
            withdrawalRejected: 'Выплаты - возврат',
          },
        },
      },
    },

    depositAndWithdrawal: {
      tabs: {
        deposit: 'Входящие',
        withdrawal: 'Исходящие',
      },

      table: {
        columns: {
          time: 'Время (UTC)',
          coinType: 'Монета',
          type: 'Тип',
          coinAmount: 'Сумма',
          address: 'Адрес кошелька',
          hash: 'TXID',
          status: 'Статус',
          commission: 'Комиссия',
        },

        filter: {
          type: {
            label: 'Тип',

            values: {
              withdrawal: 'Вывод средств',
              autoWithdrawal: 'Автовыплаты',
              internalTransfer: 'Внутренные переводы',
              autoInternalTransfer: 'Внутренние авто-переводы',
            },
          },

          status: {
            label: 'Статус',
          },
        },

        emptyHash: 'Транзакция скоро будет создана',
      },

      tooltip: {
        withdrawalTxid: 'Hash транзакции добавляется в момент формирования выплаты',
      },
    },

    resetProcess: {
      steps: {
        confirmation: {
          title: 'Подтверждение',

          reminders: {
            title: 'Напоминание',
          },

          content: {
            title: 'Внимание',
          },
        },

        accountVerification: {
          title: 'Верификация аккаунта',

          content: {
            title: 'Верификация аккаунта',
          },
        },

        securityVerification: {
          title: 'Верификация e-mail',

          content: {
            title: 'Код подтверждения отправлен на Ваш адрес ',
            desc: 'Если Вы не нашли письма с кодом среди входящих писем, проверьте папку "Спам"',
          },
        },

        identityVerification: {
          title: 'Верификация личности',

          content: {
            title: 'Загрузка файлов',
            desc: 'Пожалуйста, загрузите следующие документы. Служба поддержки рассмотрит их в течение 7 рабочих дней.',

            requirements: {
              title: 'Скриншоты предыдущих писем от Neopool',

              first:
                'Скриншоты писем от и для Neopool. Убедитесь, что адрес отправителя и получателя, тема письма и время отправки четко видны на скриншоте.',
              second: 'Загрузите 1 - {{value}} четких и хорошо читаемых скриншота.',
              third: 'Разрешены файлы не более {{value}} в формате {{types}}',
            },
          },
        },

        result: {
          title: 'Готово',

          content: {
            title: 'Загрузка успешна, файлы ждут рассмотрения.',
            desc: 'Мы рассмотрим скриншоты в течение 7 рабочих дней. После их рассмотрения Вы получите письмо с результатами на e-mail',
          },
        },
      },

      error: {
        title: 'Сброс пароля для выплат в данный момент невозможен',
        subTitle: 'Страница загрузилась некорректно. Попробуйте позже.',
      },
    },

    resetPaymentPassword: {
      title: 'Сброс пароля для выплат',

      steps: {
        confirmation: {
          content: {
            noticements: {
              first:
                'После сброса пароля для выплат все внешние выплаты и внутренние переводы, а также смена адреса для автовыплат и настроек автопереводов будут недоступны в течение 48 часов.',
              second:
                'После сброса пароля для выплат Ваш аккаунт временно потеряет дополнительную защиту, обеспечиваемую этим паролем. Пожалуйста, как можно скорее установите новый пароль. Также рекомендуем убедиться, что у Вас включена двухфакторная аутентификация.',
            },
          },
        },
      },

      error: {
        noPaymentPassword: {
          title: 'Нет пароля для выплат',
          subTitle: 'Пароль для выплат невозможно сбросить, так как он не установлен. о здесь:',
        },

        prevRequestActive: {
          title: 'Запрос на сброс пароля в обработке',
          subTitle:
            'Вы недавно запросили сброс пароля для выплат. Ваши документы еще не рассмотрены. Пожалуйста, дождитесь письма по результатам рассмотрения.',
        },
      },
    },

    resetGa: {
      title: 'Сброс двухфакторной аутентификации',

      steps: {
        confirmation: {
          reminders: {
            first:
              'Если у Вас есть доступ к Google Authenticator - можете быстро выключить двухфакторную аутентификацию <Link>здесь</Link>.',
            second:
              'Если Вы потеряли доступ к устройству с Google Authenticator, можете воспользоваться одноразовыми кодами. С их помощью Вы выключите двухфакторную аутентификацию и включите ее снова на другом устройстве <Link>здесь</Link>.',
          },

          content: {
            noticements: {
              first:
                'После сброса пароля для выплат все внешние выплаты и внутренние переводы, а также смена адреса для автовыплат и настроек автопереводов будут недоступны в течение 48 часов.',
              second:
                'После сброса двухфакторной аутентификации ваш аккаунт временно потеряет дополнительную защиту, обеспечиваемую Google Authenticator. Пожалуйста, как можно скорее настройте двухфакторную аутентификацию заново.',
            },
          },
        },
      },

      error: {
        noGa: {
          title: 'Двухфакторная аутентификация выключена',
          subTitle:
            'Двухфакторную аутентификацию невозможно сбросить, так как она выключена. Включить ее на новом устройстве можно здесь:',
        },

        prevRequestActive: {
          title: 'Запрос на сброс двухфакторной аутентификации в обработке',
          subTitle:
            'Вы недавно запросили сброс двухфакторной аутентификации. Ваши документы еще не рассмотрены. Пожалуйста, дождитесь письма по результатам рассмотрения.',
        },
      },
    },

    referralRewardsIntro: {
      title1: 'Представляем Реферальную программу Neopool:',
      title2: 'Получайте BTC без майнинга!',
      desc1: 'Присоединитесь к Реферальной программе и начните получать вознаграждение!',
      desc2: 'Так мы говорим Вам спасибо за то, что помогаете знакомым присоединиться к нам.',
      desc3: 'Теперь подробнее',

      tiers: {
        normal: {
          title: 'Общие условия:',
          desc1:
            '— Наслаждайтесь вознаграждениями, равными щедрым <accent>10% комиссии</accent> пула, применяемой к начислениям Ваших друзей, <accent>в течение 12 месяцев</accent>.',
          desc2: 'О да, каждый день, когда майнеры Ваших друзей активны, вы получаете часть комиссии пула в BTC.',
        },

        ambassador: {
          title: 'Амбассадор:',
          desc1:
            '— Получив статус Амбассадора, Вы станете получать еще больше. Амбассадоры получают <accent>20% комиссии</accent> пула <accent>бессрочно</accent>!',
          desc2: 'То есть, Вы продолжаете получать награды так долго, сколько Ваши друзья зарабатывают с нами.',
        },
      },

      friendsBonus: {
        title: 'А в чем выгода моим друзьям?',
        desc: 'Ваши друзья получат особенный бонус - <accent>50-процентную скидку</accent> на PPS-комиссию пула <accent>на первые 30 дней</accent> майнинга.',
      },

      info: {
        title4: 'Как начать зарабатывать?',
        list4: {
          item1: 'Зайдите на свою страницу <Link>{{link}}</Link> и скопируйте уникальную реферальную ссылку.',
          item2: 'Поделитесь этой ссылкой с друзьями, родными и коллегами.',
          item3:
            'После регистрации и подключения первого устройства к пулу, Вы начнете получать вознаграждение. Увидеть полученные вознаграждения можно на странице <Link>{{link}}</Link>.',
          referralRewardLink: 'реферальных вознаграждений',
          item4:
            'Когда у Вас будет 5 активных рефери, на странице реферальных вознаграждений у Вас появится кнопка "Стать Амбассадором". Нажмите кнопку - мы проверим Ваших рефералов и их активность, а Вы станете Амбассадором и с этого же дня будете получать более высокое вознаграждение.',
        },

        note: 'Примечание:',
        disclaimer1:
          '* Реферальное вознаграждение переводится на Ваш основной аккаунт автоматически. Вся информация доступна на страницах <MyBillsLink>{{myBills}}</MyBillsLink> и <RefRewLink>{{referralRewards}}</RefRewLink>',

        disclaimer2: '** Статус Амбассадора сохраняется при наличии 5 и более активных приглашенных.',

        links: {
          referralRewards: 'Реферальные вознаграждения',
          myBills: 'Операции по счёту',
        },
      },

      join: {
        title1: 'Присоединяйтесь к нашей Реферальной программе и получайте выгоду!',
        title2: '',
      },
    },

    notFoundPage: {
      title: 'Упс... Страница не найдена',
      description: 'Похоже, страница, которую Вы ищете, еще не добыта или не подтверждена',
    },

    modal: {
      changePhone: {
        title: 'Привязка телефона',
      },

      changeFrequency: {
        title: 'Настройка частоты оповещений',
      },

      changeEmail: {
        title: 'Настройка e-mail',
      },

      changeTelegram: {
        title: 'Привязка аккаунта Telegram',

        info: {
          instructionOne: {
            first: 'Найдите наш <1>{{value}}</1> в Telegram',
            second: 'Нажмите /start или команду /getID, и получите ID чата.',
            third: 'Вернитесь сюда и сохраните ID чата.',
          },

          instructionTwo: {
            title: 'Вы можете настроить оповещения в Telegram-группу. Вот как это сделать:',

            first:
              'Добавьте <1>{{value}}</1> в группу, в которой хотите видеть уведомления, и отправьте /getID - Вы получите ID чата (ID группового чата начинается со знака "минус")',
            second: 'Вернитесь сюда и сохраните ID чата (включая знак "минус").',
          },
        },
      },

      exportToEmail: {
        title: 'Экспорт на e-mail',
      },

      securityNotice: {
        title: 'Важное напоминание',

        message: {
          bindPayPasswordOrGa:
            'Для обеспечения безопасности Вашего аккаунта привяжите, пожалуйста, пароль для выплат и настройте двухфакторную аутентификацию.',
          bindPayPassword: 'Для обеспечения безопасности Вашего аккаунта привяжите, пожалуйста, пароль для выплат.',
          bindGa: 'Для обеспечения безопасности Вашего аккаунта настройте, пожалуйста, двухфакторную аутентификацию.',
        },

        button: {
          bindPayPassword: 'Привязать пароль для выплат',
          bindGa: 'Включить двухфакторную аутентификацию',
        },
      },

      disablePaymentPassword: {
        title: 'Верификация аккаунта',
      },

      authentication: {
        title: 'Верификация аккаунта',
      },

      paymentBlock: {
        title: 'Платёжные операции заблокированы',
        desc: 'Из соображений безопасности выплаты с Вашего аккаунта приостановлены до  {{unlockDate}}.',

        disabledOperations: 'Временно недоступные операции:',

        operations: {
          withdrawal: 'выплаты (в том числе внутренние переводы)',
          autoWithdrawal: 'изменение настроек автовыплат',
          settingsChange: 'изменение настроек автопереводов',
          walletChange: 'изменение кошелька',
        },

        thanks: 'Спасибо за понимание.',
      },

      userEmail: {
        title: 'Настройка e-mail',

        steps: {
          email: 'E-mail',
          auth: 'Авторизация',
          confirmation: 'Подтверждение',
          completed: 'Готово',
        },

        alert: {
          codeSent:
            'Код подтверждения был отправлен на ваш адрес электронной почты <Email>{{email}}</Email>. Пожалуйста, введите код в поле ниже.',
        },
      },

      signInPassword: {
        title: 'Изменение пароля для входа в приложение',

        steps: {
          password: 'Пароль',
          auth: 'Авторизация',
          completed: 'Готово',
        },
      },
    },

    widgets: {
      chatBot: {
        title: 'Neopool Chat',
      },
    },

    form: {
      label: {
        username: 'Имя пользователя',
        contact: 'Ваше имя',
        companyName: 'Компания',
        countryOrRegion: 'Страна / регион',
        telegram: 'Telegram',
        email: 'E-mail',
        telephone: 'Телефон',
        explainNeeds: 'Какой величиной хэшрейта Вы располагаете?',
        password: 'Пароль',
        passwordConfirm: 'Подтверждение пароля',
        newPassword: 'Новый пароль',
        confirmNewPassword: 'Подтверждение пароля',
        gaCode: 'Код Google Authenticator',
        recoveryCode: 'Одноразовый код',
        paymentPassword: 'Пароль для выплат',
        currentPaymentPassword: 'Текущий пароль для выплат',
        phoneNumber: 'Phone Number',
        thresholds: 'Порог оповещения',
        fluctuation: 'Колебания',
        activeWorkers: 'Количество активных устройств',
        newPaymentPassword: 'Новый пароль для выплат (6 цифр)',
        newPaymentPasswordConfirm: 'Подтверждение пароля для выплат (6 цифр)',
        telegramChatId: 'ID Telegram-чата',
        deduction: 'Сумма к списанию',
        comission: 'Комиссия',
        wallet: 'Адрес кошелька',
        frequency: 'Частота',
        note: 'Описание',
        accessLevel: 'Уровень доступа',
        withdrawalThreshold: 'Минимальная сумма автовыплат',
      },

      button: {
        export: 'Экспорт',
        submit: 'Готово',
        confirm: 'Подтвердить',
        agreeAndContinue: 'Принять и продолжить',
        disagree: 'Отказаться',
        save: 'Сохранить',
        check: 'Проверить',
        removeAll: 'Удалить все',
        goBack: 'Назад',
        next: 'Далее',
        resetCode: 'Отправить еще раз',
        browse: 'Загрузить',
        gotIt: 'Понятно',
        signUp: 'Зарегистрироваться',
        logIn: 'Войти',
        sendLink: 'Отправить ссылку',
        hide: 'Скрыть',
        yesHide: 'Да, скрыть.',
        cancel: 'Отмена',
        done: 'Готово',
        create: 'Создать',
        search: 'Поиск',
        view: 'Посмотреть',
        edit: 'Изменить',
        learnMore: 'Узнать больше',
        joinNow: 'Присоединяйтесь сейчас',
        getVipApp: 'Получить VIP-условия',
        autorefresh: 'Автообновление',
        refresh: 'Обновить',
        skip: 'Пропустить',
        remove: 'Удалить',
        showDetails: 'Посмотреть',

        password: {
          reset: 'Установить пароль',
        },

        appStore: {
          title: 'Загрузите в',
          platform: 'App Store',
        },

        googlePlay: {
          title: 'Доступно в',
          platform: 'Google Play',
        },
      },
    },

    notification: {
      success: {
        changesSaved: 'Изменения сохранены успешно.',
        changeCurrentPayment: 'Порог автовыплат успешно изменен',
        withdrawal: 'Запрос на выплату отправлен',
        twoFAEnabled: 'Двухфакторная аутентификация успешно включена',
        changePhone: 'Номер телефона успешно изменен',
        changeTelegram: 'ID чата Telegram успешно изменен',
        changeNotifications: 'Настройки уведомлений успешно обновлены',
        changePaymentPassword: 'Успешно. Пароль для выплат изменен.',
        resendVerificationCode: 'Код подтверждения выслан повторно',
        removeApiKey: 'Ключ API успешно удалён',
        editApiKey: 'Ключ API успешно изменён',
        createApiKey: 'Ключ API успешно создан',
      },

      error: {
        internalTransfer: 'Внутренний перевод не осуществлен. Попробуйте позже.',

        files: {
          tooManyFiles: 'Количество файлов должно быть не более {{value}}',
          fileTooSmall: 'Размер файла должен быть больше {{value}}',
          fileTooLarge: 'Размер файла должен быть меньше {{value}}',
          fileInvalidType: 'Неверный тип файла',
        },
      },

      internal: {
        reset2fa:
          'Вы воспользовались одноразовым кодом для входа в аккаунт. Если у Вас больше нет доступа к приложению Google Authenticator, отключите двухфакторную аутентификацию и включите ее снова с использованием нового устройства <Link>здесь</Link>.',
        noInternet: 'Нет интернет-соединения. Пожалуйста, проверьте соединение и перезагрузите страницу',
        paymentBlock:
          'Из соображений безопасности выплаты с Вашего аккаунта приостановлены до  {{unlockDate}}.  Временно недоступные операции: выплаты (в том числе внутренние переводы), изменение настроек автовыплат и автопереводов, изменение кошелька. Спасибо за понимание.',
      },
    },

    status: {
      transaction: {
        sent: 'Отправлено',
        failed: 'Отклонено',
        rejected: 'Возврат',
        onHold: 'Удержано',
      },
    },

    link: {
      myBills: 'Операции по счёту',
      depositAndWithdrawal: 'Переводы и выплаты',
    },

    pfChatWidget: {
      title: 'Мы на связи в мессенджерах',
      qrCodeDesc: 'Напишите с телефона',
    },

    common: {
      yes: 'Да',
      no: 'Нет',
      on: 'вкл',
      off: 'выкл',
      all: 'Все',
      from: 'от',
      to: 'до',
      or: 'или',
      noData: 'Нет данных',
      notSet: 'Не установлен',
      enabled: 'Включено',
      items: 'предметов',
      main: 'Основной',
      mainLowerCase: 'основной',
      subAccountLowerCase: 'суб-аккаунт',
      addresses: 'адресов: {{count}}',
      accounts: 'аккаунтов: {{count}}',
      day: '1 день',
      days: '{{days}} дней',
      noAccess: 'Нет доступа',
      loading: 'Загрузка...',
      hours: '{{hours}} часов',
      hour: '1 час',
      dropFiles: 'Перетащите файлы сюда',
      coin: 'Монета',
      per: 'на',

      table: {
        actions: {
          delete: 'Удалить',
          copy: 'Скопировать',
          edit: 'Изменить',
        },
      },

      forgotLinks: {
        paymentPassword: 'Забыли пароль для выплат?',
        gaNoAccess: 'Google Authenticator недоступен',
        ga: 'Сброс двухфакторной аутентификации и одноразовых кодов',
        recoveryCodes: 'Потеряли одноразовые коды?',
      },

      interval: {
        '5sec': '5 секунд',
        '10sec': '10 секунд',
        '30sec': '30 секунд',
        '1min': '1 минута',
        '3min': '3 минуты',
        '10min': '10 минут',
        '30min': '30 минут',
        '1h': '1 час',
      },
    },

    payment: {
      paymentSettings: {
        title: 'Настройки выплат',
        balance: 'Баланс ({{coin}})',
        minimalPayment: 'Минимальные выплаты ({{coin}})',
        paymentTime: 'Время проведения выплат (UTC)',
        paymentAddress: 'Payment адрес',
        currentPayment: 'Автовыплаты',
        walletAddressLabel: 'Адрес кошелька',
        newWalletSet: 'Кошелек сохранен',
        assets: 'Активы',
        internalTransfer: 'Автоматические внутренние переводы',
      },
    },

    tooltip: {
      allowedWalletTypes: 'Допустимые типы кошельков:',
      copied: 'Скопировано',
      copy: 'Копировать',
      copyRow: 'Копировать строку',
      expand: 'Увеличить',

      balanceTooltip: {
        onHold: 'Заморожено',
        paymentDue: 'Расчетное время выплаты',
      },
    },

    validation: {
      required: 'Обязательное поле',
      digitsOnly: 'Должен состоять только из цифр',
      lowerCaseOnly: 'Только строчные буквы',
      lettersNumbersOnly: 'Должно содержать только цифры и строчные буквы латиницы',
      hasUpperCase: 'Заглавные буквы',
      hasLowerCase: 'Строчные буквы',
      hasNumber: 'Цифры',
      hasSpecialChar: 'Специальные символы',
      mustMatch: 'Поля должны совпадать',
      beginsWithLetter: 'Должно начинаться со строчной буквы латиницы',
      fractionalNumbers: 'Числовое значение',
      naturalNumbers: 'Только число',
      walletAddress: 'Адрес не поддерживается',
      phoneNumber: 'Неверный номер телефона ',
      integer: 'Числовое значение',
      telegramChatId: 'Неверный ID чата',
      confirmAll: 'Подтвердите, что Вы понимаете риски и условия, и готовы продолжить',
      satoshi: 'Должно быть больше, чем сатоши',
      noInjection: 'Символы * < > [ ] { } недоступны',

      password: {
        length: '{{min}} - {{max}} символа',
        upperCase: 'Заглавные буквы',
        lowerCase: 'Строчные буквы',
        numbers: 'Цифры',
        specialCharacters: 'Специальные символы',
        shouldMatchWithPassword: 'Пароли должны совпадать',
        mustMatch: 'Пароли не совпадают',
      },

      ga: {
        length: 'Код состоит из 6 цифр',
      },

      paymentPassword: {
        length: 'Должен состоять из 6 цифр',
      },

      verificationCode: {
        length: 'Код состоит из 6 цифр',
      },

      email: {
        required: 'Введите Ваш e-mail',
        notValid: 'Введите действительный e-mail',
      },

      username: {
        length:
          'Имя пользователя должно состоять из 2 - 15 символов (цифры и строчные буквы латиницы) и не должно начинаться с цифры',
      },

      сaptcha: {
        required: 'Подтвердите, что Вы человек',
      },
    },

    success: {
      export: {
        emailSend: 'Данные отправлены на Ваш e-mail',
      },

      vipApplication: 'Успешно! Пожалуйста, ожидайте письма от Neopool',
      betaTestApplication: 'Успешно! Пожалуйста, ожидайте письма от Neopool',
      referralRationApplication: 'Поздравляем! Вы приобрели статус амбассадора',
      changes: 'Изменения сохранены успешно.',
      copy: 'Скопировано в буфер обмена',
      workersRemoved: 'Успешно! Устройства удалены.',
    },

    error: {
      copy: 'Не скопировано. Пожалуйста, обновите страницу и попробуйте снова.',

      captcha: {
        title: 'Ошибка проверки CAPTCHA',
        desc: 'Мы не смогли подтвердить вашу попытку входа из-за невозможности подтверждения CAPTCHA. Причиной могли быть необычная активность или автоматизированный процесс.<br><br/>Пожалуйста, попробуйте следующее:<ul><li>Убедитесь, что правильно выполнили проверку CAPTCHA.</li><li>Очистите кэш и файлы cookie вашего браузера, затем попробуйте снова.</li><li>Используйте другой браузер или устройство, если проблема сохраняется.</li></ul> <br></br> Если вы продолжаете испытывать трудности, пожалуйста, свяжитесь с нашей службой поддержки по адресу <Link>support@neopool.com</Link>.<br></br><br></br>Спасибо за понимание.',
      },
    },

    requestErrors: {
      userNameAlreadyRegistered: 'Данное имя пользователя уже зарегистрировано',
      accountNameAlreadyRegistered: 'Данное название аккаунта уже зарегистрировано',
      invalidParameterValue: 'Неверные значения',
      captcha1Failed: 'Подтвердите, что Вы не робот',
      captcha2Failed: 'Проверка CAPTCHA не пройдена. Попробуйте снова через несколько секунд.',
      incorrectTOTP: 'Ошибка. Неверные учетные данные',
      incorrectPassword: 'Ошибка. Неверные учетные данные',
      incorrectExistingPassword: 'Ошибка. Неверные учетные данные',
      invalidCredentials: 'Ошибка. Неверные учетные данные',
      invalidCode: 'Ошибка. Неверный код',
      userNotFound: 'Ошибка. Неверные учетные данные',
      tooManyRequests: 'Ошибка. Слишком частые запросы. Пожалуйста, попробуйте позже.',

      forbidden: 'Forbidden',
      serverIsNotResponding: 'Server is not responding',
      unauthorized: 'You are not authorized',
      pageNotFound: 'Page not found',
      badRequest: 'Bad request',

      auth: {
        invalidData: 'Неправильные данные',
        emptyData: 'Введите требуемые данные',
        invalidAccount: 'Ошибка. Неверный аккаунт получателя',
      },

      payment: {
        nsf: 'Ошибка. Недостаточно средств.',
        block: 'Ошибка. Платёжные операции заблокированы',
        minLimit: 'Ошибка. Введённое значение меньше минимальной суммы перевода',
      },

      fee: {
        nsf: 'Вывод средств в настоящий момент недоступен. Попробуйте позже или свяжитесь со службой поддержки.',
        notFound: 'Вывод средств в настоящий момент недоступен. Попробуйте позже или свяжитесь со службой поддержки.',
        lowAmount: 'Сумма вывода не может быть меньше, чем комиссия',
        dustAmount: 'Сумма вывода очень маленькая. Пожалуйста, попробуйте вывести большую сумму',
        addressNotValid: 'Невалидный адрес кошелька',
        unknown: 'Вывод средств в настоящий момент недоступен. Попробуйте позже или свяжитесь со службой поддержки.',
      },

      ambassador: {
        notEligible:
          'Ошибка. У вас недостаточно активных рефералов, чтобы стать амбассадором ({{activeRefs}} из {{limitRefs}}).',
      },

      apiKeys: {
        keyLimitReached:
          'Ошибка. Превышено допустимое количество ключей API. Для того, чтобы изменить это значение, пожалуйста, свяжитесь со службой поддержки.',
        accessLevelNotPermitted: 'Ошибка. Уровень доступа не разрешён',
      },

      common: 'Что-то не так. Пожалуйста, попробуйте позже',
    },
  },
};
