import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Alert } from 'antd';

import { ArrowDownIcon } from 'assets/images';

import './CaptchaErrorAlert.styles.scss';

const CaptchaErrorAlert = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const openClass = open ? 'np-captcha-error-alert--open' : '';

  const onChangeOpen = () => {
    setOpen(prev => !prev);
  };

  return (
    <Alert
      showIcon
      type="error"
      message={t('error.captcha.title')}
      description={
        open ? (
          <Trans
            i18nKey="error.captcha.desc"
            components={{ Link: <NavLink to={`mailto:support@neopool.com`} />, ul: <ul />, li: <li /> }}
          />
        ) : (
          ' '
        )
      }
      action={<ArrowDownIcon onClick={onChangeOpen} />}
      className={`np-captcha-error-alert ${openClass}`}
    />
  );
};

export default CaptchaErrorAlert;
