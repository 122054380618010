import React from 'react';
import { Collapse } from 'antd';
import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import { ArrowDownIcon } from 'assets/images';

import './Accordion.styles.scss';

const { Panel } = Collapse;

const Accordion = ({
  header,
  children,
  divider,
  size = 'large',
  defaultActive,
  type = 'default',
  className = '',
  ...props
}) => {
  const expandIcon = ({ isActive }) => {
    switch (type) {
      case 'ghost':
        return isActive ? <ArrowDownIcon /> : <ArrowDownIcon style={{ transform: 'rotate(180deg)' }} />;

      case 'default':
      default:
        return isActive ? <MinusCircleFilled /> : <PlusCircleFilled />;
    }
  };

  const sizeClass = size === 'large' ? 'np-large' : 'np-small';
  const dividerClass = divider ? 'np-divided' : '';
  const typeClass = `np-accordion--${type}`;

  const defaultActiveKey = defaultActive ? ['collapse-key'] : null;

  return (
    <Collapse
      size="large"
      bordered={false}
      expandIcon={expandIcon}
      expandIconPosition="end"
      defaultActiveKey={defaultActiveKey}
      className={`np-accordion ${dividerClass} ${typeClass} ${className}`}
      {...props}
    >
      <Panel header={header} className={sizeClass} key="collapse-key">
        {children}
      </Panel>
    </Collapse>
  );
};

export default Accordion;
