import { apiUrls } from 'constants/urls';
import request from './request.service';
import asyncErrorHandler from 'utils/requests/errorHandler';

export const sendUtmTags = async url => {
  const callback = async () => {
    return await request(apiUrls.marketing.utm, {
      method: 'POST',
      body: { utm_data: url },
    });
  };

  return await asyncErrorHandler(callback);
};
