export const AgreementStructure = [
  {
    title: 'userAgreement',
    paragraphsCount: 3,
  },
  {
    title: 'termOfUse',
    paragraphsCount: 5,
  },
  {
    title: 'definitions',
    paragraphsCount: 9,
  },
  {
    title: 'termsOfService',
    paragraphsCount: 1,
  },
  {
    title: 'AmlKycCtfPolicy',
    paragraphsCount: 13,
  },
  {
    title: 'services',
    paragraphsCount: 3,
  },
  {
    title: 'minerRightsAndObligations',
    paragraphsCount: 16,
  },
  {
    title: 'serviceChargesAndPayment',
    paragraphsCount: 7,
  },
  {
    title: 'confidentiality',
    paragraphsCount: 7,
  },
  {
    title: 'suspensionAndTermination',
    paragraphsCount: 6,
  },
  {
    title: 'disclaimerOfWarranty',
    paragraphsCount: 6,
  },
  {
    title: 'indemnity',
    paragraphsCount: 1,
  },
  {
    title: 'nonExclusivity',
    paragraphsCount: 1,
  },
  {
    title: 'forceMajeure',
    paragraphsCount: 3,
  },
  {
    title: 'thirdPartyRights',
    paragraphsCount: 1,
  },
  {
    title: 'severabilityAndWaiver',
    paragraphsCount: 4,
  },
  {
    title: 'entireAgreement',
    paragraphsCount: 1,
  },
  {
    title: 'governingLawAndDisputeResolution',
    paragraphsCount: 2,
  },
  {
    title: 'intellectualProperty',
    paragraphsCount: 2,
  },
  {
    title: 'linksToOtherWebsites',
    paragraphsCount: 3,
  },
  {
    title: 'yourFeedbackToUs',
    paragraphsCount: 1,
  },
];

export const TITLE_PATH = 'title';
export const PARAGRAPH_PATH = 'text.part';
export const PREFIX_PATH = 'signUp.agreementModal.document';
