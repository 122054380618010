import React from 'react';
import { NavLink } from 'react-router-dom';

import { LogoLightIcon } from 'assets/images';

import { publicUrls } from 'constants/urls';

import './Logo.styles.scss';

const Logo = () => {
  return (
    <NavLink to={publicUrls.main} className="np-public-layout-logo">
      <LogoLightIcon />
    </NavLink>
  );
};

export default Logo;
