import { useEffect } from 'react';
import { createSearchParams, useLocation } from 'react-router-dom';
import { sendUtmTags } from 'services/marketing.service';

const UTM_PARAM_PREFIX = 'utm_';

const useUtmTags = () => {
  const location = useLocation();

  const { search, pathname } = location || {};

  useEffect(() => {
    const queryParams = Array.from(createSearchParams(search));

    const utmParams = queryParams.filter(param => param[0].startsWith(UTM_PARAM_PREFIX));

    if (utmParams.length) {
      const base64Url = btoa(window.location.href);
      sendUtmTags(base64Url);
    }
  }, [search, pathname]);
};

export default useUtmTags;
