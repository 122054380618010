import React from 'react';

import Logo from '../Logo';
import { LanguageSelect } from 'modules';
import AuthButtons from '../AuthButtons';

import { MenuVariant } from 'components/selects/Menu';
import MainContainer from 'components/layouts/common/MainContainer';

import './Header.styles.scss';

const Header = () => {
  return (
    <header className="np-public-layout-header">
      <MainContainer className="np-public-layout-header__content">
        <Logo />

        <div className="np-public-layout-header__content-right">
          <LanguageSelect titleCase variant={MenuVariant.OUTLINED} className="np-public-layout-header__select" />

          <AuthButtons />
        </div>
      </MainContainer>
    </header>
  );
};

export default Header;
