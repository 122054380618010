import { useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { CaptchaErrorType } from 'constants/errors';

const DEFAULT_NAME = 'default';

const CAPTCHA_FAIL_ERRORS = Object.values(CaptchaErrorType);

const useCaptcha = (name = DEFAULT_NAME) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [isCaptchaReady, setIsCaptchaReady] = useState(false);
  const [captchaTokenV3, setCaptchaTokenV3] = useState(null);
  const [isCaptchaError, setIsCaptchaError] = useState(false);

  const verifyCaptchaV3 = useCallback(async () => {
    const token = await executeRecaptcha(name);

    setCaptchaTokenV3(token);
    setIsCaptchaReady(true);
  }, [executeRecaptcha, name]);

  const checkCaptchaError = error => {
    const errorKey = error.body?.key;
    const isCaptchaFailed = CAPTCHA_FAIL_ERRORS.includes(errorKey);

    if (isCaptchaFailed) {
      setIsCaptchaError(true);
      return errorKey;
    }
  };

  const getCaptchaParams = captchaTokenV2 => {
    return isCaptchaError
      ? { ['g-recaptcha-response2']: captchaTokenV2 }
      : { ['g-recaptcha-response']: captchaTokenV3 };
  };

  useEffect(() => {
    verifyCaptchaV3();
  }, [verifyCaptchaV3]);

  return { isCaptchaError, isCaptchaReady, getCaptchaParams, checkCaptchaError };
};

export default useCaptcha;
