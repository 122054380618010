import React from 'react';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { Form, Button } from 'components';

import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

import './FormButtons.styles.scss';

const FormButtons = ({
  color,
  loading,
  submitDisabled,
  goBackDisabled,
  showGoBack,
  marginTop = 36,
  marginBottom = 0,
  goBackLabel = 'form.button.goBack',
  submitLabel = 'form.button.confirm',
  onGoBack = () => {},
}) => {
  const { t } = useTranslation();

  const { isMobileView } = useWindowResponsiveness(500);

  return (
    <Space
      wrap
      direction={isMobileView ? 'vertical' : 'horizontal'}
      className="np-form-buttons"
      style={{ marginTop, marginBottom }}
    >
      {showGoBack && (
        <Button block color={color} disabled={loading || goBackDisabled} size="large" type="default" onClick={onGoBack}>
          {t(goBackLabel)}
        </Button>
      )}

      <Form.Item>
        <Button
          block
          color={color}
          loading={loading}
          disabled={loading || submitDisabled}
          size="large"
          htmlType="submit"
        >
          {t(submitLabel)}
        </Button>
      </Form.Item>
    </Space>
  );
};

export default FormButtons;
