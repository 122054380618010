import React from 'react';
import { Input as AntdInput } from 'antd';

import { InputVariant } from './constants/input';

import './Input.styles.scss';

const Input = React.forwardRef(({ variant = InputVariant.FILLED, size = 'large', className = '', ...props }, ref) => {
  const variantClass = `np-input__${variant}`;

  return <AntdInput ref={ref} size={size} className={`np-input ${variantClass} ${className}`} {...props} />;
});

Input.Group = AntdInput.Group;

// TODO: Reuse input
Input.Password = React.forwardRef(
  ({ variant = InputVariant.FILLED, size = 'large', className = '', ...props }, ref) => {
    const variantClass = `np-input__${variant}`;

    return <AntdInput.Password ref={ref} size={size} className={`np-input ${variantClass} ${className}`} {...props} />;
  },
);

Input.Search = React.forwardRef(({ variant = InputVariant.FILLED, size = 'large', className = '', ...props }, ref) => {
  const variantClass = `np-input__${variant}`;

  return (
    <AntdInput.Search
      ref={ref}
      size={size}
      className={`np-input np-input-search ${variantClass} ${className}`}
      {...props}
    />
  );
});

Input.TextArea = React.forwardRef(
  ({ variant = InputVariant.FILLED, size = 'large', className = '', ...props }, ref) => {
    const variantClass = `np-input__${variant}`;

    return (
      <AntdInput.TextArea
        ref={ref}
        size={size}
        className={`np-input np-input-textarea ${variantClass} ${className}`}
        {...props}
      />
    );
  },
);

export default Input;
