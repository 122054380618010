import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format, parse } from 'date-fns';

import Chart from './components/Chart';
import { FullScreenChartModal } from 'components';

import useModal from 'hooks/common/useModal';
import useWatcherData from 'hooks/watcher/useWatcherData';
import useNotifications from 'hooks/common/useNotifications';
import useWorkerLogsQuery from 'hooks/requests/admin/query/useWorkerLogsQuery';

import { startProcessWorkersLog } from 'services/admin/adminLogs.service';

import { Granularity } from './constants/granularity';
import { DATE_DOT_FORMAT_WITH_TIME, DATE_SLASH_FORMAT_WITH_FULL_TIME } from 'constants/date';

const DefaultGranularities = [Granularity.H5, Granularity.H1H, Granularity.H1D];

const HashrateChart = ({
  titleClass,
  title = 'hashrateChart.title',
  column,
  accountId,
  workerName,
  onChangeColumn,
  requestCallback,
  granularities = DefaultGranularities,
  ...props
}) => {
  const { t } = useTranslation();
  const { notificationApi } = useNotifications();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [granularity, setGranularity] = useState(column || granularities[0]);

  const { open, onOpen, onClose } = useModal();

  const { watcher, isAdmin, isWatcherPage } = useWatcherData();

  const { refetch } = useWorkerLogsQuery(
    { workername: workerName, username: watcher?.username },
    { refetchOnMount: false, enabled: isWatcherPage && isAdmin && !!workerName },
  );

  const onEvents = {
    click: async event => {
      const chartDate = event.name;

      const date = parse(chartDate, DATE_DOT_FORMAT_WITH_TIME, new Date());
      const dateStr = format(date, DATE_SLASH_FORMAT_WITH_FULL_TIME);

      const { success, error, errorMessage } = await startProcessWorkersLog({
        workername: workerName,
        date: dateStr,
      });

      if (success) {
        return refetch();
      }

      if (error?.status) {
        notificationApi.error({ message: t(errorMessage) });
      }
    },
  };

  const handleChangeColumn = value => {
    setGranularity(value);

    if (onChangeColumn) {
      onChangeColumn(value);
    }
  };

  const getChartData = useCallback(async () => {
    setLoading(true);

    const result = await requestCallback({
      column: granularity,
    });

    const { data, success } = result || {};

    if (success && data?.type !== 'error') {
      setData(data);
    } else {
      setData(null);
    }

    setLoading(false);
  }, [granularity, requestCallback]);

  useEffect(() => {
    getChartData();
  }, [accountId, getChartData]);

  return (
    <>
      <Chart
        enableExpend
        data={data}
        title={t(title)}
        loading={loading}
        titleClass={titleClass}
        granularity={granularity}
        granularities={granularities}
        onExpand={onOpen}
        onChangeGranularity={handleChangeColumn}
        onEvents={isAdmin && isWatcherPage && workerName ? onEvents : null}
        {...props}
      />

      <FullScreenChartModal title={t(title)} open={open} onClose={onClose}>
        <Chart
          width="100%"
          height="100%"
          data={data}
          loading={loading}
          granularity={granularity}
          granularities={granularities}
          onChangeGranularity={handleChangeColumn}
          onEvents={onEvents}
          style={{ padding: '20px 0' }}
          {...props}
        />
      </FullScreenChartModal>
    </>
  );
};

export default HashrateChart;
