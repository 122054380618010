import { StorageKey } from 'constants/localStorage';
import { v4 as uuidv4 } from 'uuid';

const getUserUuid = () => {
  const userUuid = localStorage.getItem(StorageKey.USER_UUID);

  if (userUuid) {
    return userUuid;
  }

  const newUuid = uuidv4();

  localStorage.setItem(StorageKey.USER_UUID, newUuid);

  return newUuid;
};

export default getUserUuid;
