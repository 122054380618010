import React from 'react';
import { Divider } from 'antd';

import Accordion from '../Accordion';

import './AccordionGroup.styles.scss';

const AccordionGroup = ({ items = [], gap = 16, maxWidth, type, divider }) => {
  const dividerClass = divider ? 'np-accordion-group--divider' : '';

  return (
    <div className={`np-accordion-group ${dividerClass}`} style={{ gap, maxWidth }}>
      {items.map((item, index) => (
        <>
          <Accordion key={item.key} header={item.header} type={type}>
            {item.content}
          </Accordion>

          {divider && index !== items.length - 1 && <Divider />}
        </>
      ))}
    </div>
  );
};

export default AccordionGroup;
