import React from 'react';
import { useTranslation } from 'react-i18next';

import { Spin, NoData, PageLoader } from 'components';

import './Loading.styles.scss';

const Loading = ({
  children,
  isLoading,
  isEmpty,
  className,
  showBody = true,
  inline = false,
  noMargin,
  noTip,
  fullScreen,
}) => {
  const { t } = useTranslation();
  const getTemplate = () => {
    if (isLoading) {
      if (fullScreen) {
        return <PageLoader />;
      }

      if (inline) {
        return (
          <Spin className="np-loading--inline" tip={!noTip && t('common.loading')} size="small">
            <div className="content" />
          </Spin>
        );
      } else {
        return (
          <Spin tip={!noTip && t('common.loading')} size="large">
            <div className="content" />
          </Spin>
        );
      }
    } else if (isEmpty || !showBody) {
      return <NoData />;
    } else {
      return children;
    }
  };

  return isLoading || isEmpty || !showBody ? (
    <div className={`np-loading ${className ? className : ''} ${noMargin ? 'np-loading--no-margin' : ''}`}>
      {getTemplate()}
    </div>
  ) : (
    children
  );
};

export default Loading;
