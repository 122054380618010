import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormButtons } from 'modules';
import RefetchTimer from '../RefetchTimer';
import { Form, Captcha, CodeInput } from 'components';
import CaptchaErrorAlert from 'modules/alerts/CaptchaErrorAlert';

import useForm from 'hooks/forms/useForm';
import useValidation from 'hooks/forms/useValidation';

import { CaptchaErrorType } from 'constants/errors';

import './SecurityVerificationForm.styles.scss';

const CODE_LENGTH = 6;

const SecurityVerificationForm = ({
  loading,
  disabled,
  error,
  valid,
  includeCaptcha,
  onFinish = () => {},
  resendCode = async () => {},
}) => {
  const rules = useValidation();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { submitDisabled } = useForm(form);

  const handleFinish = values => {
    onFinish(values);
  };

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      layout="vertical"
      requiredMark="optional"
      validateTrigger="onSubmit"
      name="reset-pay-pass-security-verify"
      className="np-reset-process-security-verify__form"
    >
      <Form.Item
        name="code"
        rules={[rules.required, rules.requiredLen(CODE_LENGTH, t('validation.verificationCode.length'))]}
        className="np-reset-process-security-verify__form-code"
      >
        <CodeInput type="text" fields={CODE_LENGTH} disabled={loading} isValid={valid} />
      </Form.Item>

      <RefetchTimer
        disabled={loading}
        refetch={resendCode}
        className="np-reset-process-security-verify__form-refetch-timer"
      />

      {includeCaptcha && (
        <Form.Item
          name="captchaV2"
          validateFirst
          rules={[rules.requiredWithMessage('validation.сaptcha.required')]}
          className="np-reset-process-security-verify__form-captcha"
        >
          <Captcha />
        </Form.Item>
      )}

      {error === CaptchaErrorType.COMPLETELY_FAILED && <CaptchaErrorAlert />}

      <FormButtons marginTop={12} submitDisabled={submitDisabled || disabled} loading={loading} />
    </Form>
  );
};

export default SecurityVerificationForm;
